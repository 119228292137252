@charset "utf-8";
// ====================================== //
// ESG //
.esg {
	&-about {
		padding: 40px 0;
		.idx-business-item {
			.__title {
				margin-top: 10px;
				margin-bottom: 0;
				font-size: 2.4rem;
				font-family: $font-alphabet;
				letter-spacing: 0.1em;
				line-height: 1.5;
				> span {
					color: $color-red;
					font-size: 3.6rem;
				}
			}
		}
		&-head {
			position: relative;
			display: table;
			width: 100%;
			min-height: 60px;
			margin-bottom: 40px;
			padding-left: 90px;
			font-size: 2.0rem;
			font-weight: normal;
			.__num {
				position: absolute;
				width: 60px;
				height: 60px;
				top: 50%;
				left: 0;
				background-color: $color-green2;
				text-align: center;
				color: #fff;
				font-weight: bold;
				font-family: $font-alphabet;
				font-size: 3.0rem;
				line-height: 60px;
				transform: translateY(-50%);
			}
			.__text {
				display: table-cell;
				vertical-align: middle;
			}
		}
		@include media(tablet) {
			.idx-business-item {
				.__title {
					font-size: 1.5rem;
					> span { font-size: 2.4rem; }
				}
			}
		}
		@include media(sp) {
			padding: 0 vw(50px,$sp);
			.idx-business-item {
				margin-bottom: 0;
				padding: vw(50px,$sp) 0;
				border-top: 1px solid $color-border2;
				&:first-child { border-top: none; }
			}
			&-head {
				margin-bottom: vw(40px,$sp);
				min-height: auto;
				padding-left: vw(110px,$sp);
				font-size: 1.5rem;
				.__num {
					width: vw(90px,$sp);
					height: vw(90px,$sp);
					font-size: 2.4rem;
					line-height: vw(90px,$sp);
				}
			}
		}
	}
	&-boxLink {
		display: flex;
		align-items: center;
		padding: 35px 25px;
		background-color: #fff;
		box-shadow: 0 5px 30px 0 rgba(0,0,0,.15);
		.__img {
			width: 110px;
			padding: 0 25px;
		}
	}
	&-look {
		&-anchor {
			.mod-button {
				height: 90px;
				span { line-height: 2; }
			}
			@include media(tablet) {
				.mod-button { height: 70px; }
			}
		}
		&-head {
			margin-bottom: 60px;
			padding: 25px 30px 15px;
			background-color: $color-green2;
			color: #fff;
			font-size: 2.4rem;
			rt { font-weight: normal; }
			&02 { margin-bottom: 50px; }
			@include media(tablet) {
				&02 { margin-top: 60px; }
			}
			@include media(tablet) {
				&02 {
					margin-top: vw(60px,$sp);
					margin-bottom: vw(50px,$sp);
				}
			}
		}
		&-txt {
			font-size: 1.6rem;
			line-height: 2;
		}
	}
}
