@charset "utf-8";
// ====================================== //
// block layout //
.bclo {
	&-cap {
		padding-top: 10px;
		color: $color-txt1;
		font-size: 1.4rem;
		text-align: left;
		@include media(sp) {
			padding: 10px 10px 0;
			font-size: 1.2rem;
		}
	}
	&-textBlock {
		margin-bottom: 60px;
	}
	&-textWrap {
		&-title {
			border-left: 2px solid $color-red;
			padding-left: 15px;
			font-size: 1.6rem;
			font-weight: bold;
			+ .bclo-textWrap-text {
				margin-top: 10px;
			}
			.weightlifting & { border-left: 2px solid $color-orange; }
			.rifle & { border-left: 2px solid $color-light-blue; }
			.track_and_field & { border-left: 2px solid $color-green; }

			@include media(sp) { font-size: 1.8rem; }
		}
		&-text {
			margin-top: 20px;
		}
	}
	&-link {
		margin-top: 15px;
		text-align: center;
		&:nth-of-type(1) { margin-top: 40px; }

		&-button {
			display: inline-block;
			margin: 0 auto;
			width: 250px;
			max-width: 100%;
			text-align: center;

			&_center {
				display: block;
				margin: 0 auto;
			}

			&-a {
				position: relative;
				display: block;
				width: 100%;
				height: 45px;
				background-color: transparent;
				border: 1px solid $color-red;
				color: $color-red;
				transition: .4s;
				overflow: hidden;

				.icon {
					position: absolute;
					right: 5%;
					top: 50%;
					transform: translateY(-50%);
					transition: .4s;
					width: 14px;
					height: 14px;
					fill: $color-red;
					vertical-align: middle;
					&:before {
						position: absolute;
						right: 5%;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				&:hover {
					background-color: $color-red;
					color: #fff;
					.icon { fill: #fff; }
					@include media(tablet) {
						background-color: transparent;
						color: $color-txt1;
						.icon { fill: $color-red; }
					}
				}
				.weightlifting & {
					border: 1px solid $color-orange;
					color: $color-orange;
					.icon { fill: $color-orange; }
					&:hover {
						background-color: $color-orange;
						color: #fff;
						.icon { fill: #fff; }
						@include media(tablet) {
							color: $color-txt1;
							.icon { fill: $color-orange; }
						}
					}
				}
				.rifle & {
					border: 1px solid $color-light-blue;
					color: $color-light-blue;
					.icon { fill: $color-light-blue; }
					&:hover {
						background-color: $color-light-blue;
						color: #fff;
						.icon { fill: #fff; }
						@include media(tablet) {
							color: $color-txt1;
							.icon { fill: $color-light-blue; }
						}
					}
				}
				.track_and_field & {
					border: 1px solid $color-green;
					color: $color-green;
					.icon { fill: $color-green; }
					&:hover {
						background-color: $color-green;
						color: #fff;
						.icon { fill: #fff; }
						@include media(tablet) {
							color: $color-txt1;
							.icon { fill: $color-green; }
						}
					}
				}
			}
			&-txt {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0; left: 0;
				margin: auto;
				line-height: 1.4;
			}
		}
		@include media(sp) {
			text-align: center;
			&:nth-of-type(1) { margin-top: vw(40px, $sp); }
			&-button {
				width: vw(430px, $sp);
				&-a {
					height: vw(80px, $sp);
					font-size: 1.4rem;
				}
			}
		}
	}
	&-img {
		&Wrap {
			display: table;
			width: 100%;
			text-align: center;

			&-inner {
				display: table-cell;
				vertical-align: middle;
			}
			img {
				width: 100%;
			}
			+ .bclo-textWrap { margin-top: 20px; }
		}
		@media all and (-ms-high-contrast: none) {
			*::-ms-backdrop, &Wrap {
				table-layout: fixed;
			}
		}
		&Link {
			display: block;
			transition: .4s;
			&:hover { opacity: .55; }
			+ .bclo-textWrap { margin-top: 20px; }
		}
	}

	&-col {
		&01, &02, &03, &04 {
			margin-bottom: 60px;
		}
		@include media(sp) {
			&02 {
				.space-u-20 {
					margin-top: -40px;
					&-child {
						padding-top: 40px;
					}
				}
			}
		}
		&02 {
			+ .bclo-col02 { margin-top: -40px; }
		}
		&03 {
			+ .bclo-col03 { margin-top: -40px; }
		}
		&04 {
			+ .bclo-col04 { margin-top: -40px; }
		}
		&03, &04 {
			.bclo-textWrap {
				margin-top: -5px;
				@include media(sp) { margin-top: 0; }
			}
		}
	}

	&-leftImage, &-rightImage {
		&-lg, &-sm {
			.bclo-textWrap {
				margin-top: 0;
				padding: 0;
			}
			.bclo-link {
				margin-top: 15px;
				margin-bottom: 0;
			}
		}
	}
	&-title {
		&-lg {
			width: 100%;
			margin-bottom: 60px;
			padding-bottom: 20px;
			font-size: 2.0rem;
			line-height: 1.5;
			color: $color-txt1;
			border-bottom: 1px solid $color-red;
			.weightlifting & { border-bottom: 1px solid $color-orange; }
			.rifle & { border-bottom: 1px solid $color-light-blue; }
			.track_and_field & { border-bottom: 1px solid $color-green; }
			@include media(sp) {
				margin-bottom: vw(40px, $sp);
				font-size: 2.4rem;
			}
		}
		&-sm {
			border-left: 2px solid $color-red;
			padding-left: 15px;
			margin-bottom: 30px;
			font-size: 1.6rem;
			color: $color-txt1;
			.weightlifting & { border-left: 2px solid $color-orange; }
			.rifle & { border-left: 2px solid $color-light-blue; }
			.track_and_field & { border-left: 2px solid $color-green; }
			@include media(sp) { font-size: 1.8rem; }
		}
	}
	&-list {
		margin-bottom: 60px;

		&-li {
			margin-bottom: 5px;
			font-size: 1.6rem;

			&-a {
				text-decoration: underline;
			}
		}
		&.ul-og {
			.bclo-list-li { border-bottom: 1px dotted #7d7d7d; }
		}
	}
	&-free {
		margin-bottom: 60px;
	}
}
