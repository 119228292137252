@charset "utf-8";
/* ====================================== */
/* clearfix */
@import "include/include";
%clearfix {
	zoom: 1;

	&:after {
		content: "";
		display: table;
		clear: both;
	}
}
@mixin clearfix {
	/* clearfix is already applied */
	@extend %clearfix;
}
%pseudo-block {
	position: relative;
	content: "";
	display: block;
}
%pseudo-inlineblock {
	position: relative;
	content: "";
	display: inline-block;
}
%pseudo-absolute {
	position: absolute;
	content: "";
	display: block;
}
@mixin pseudo-block { @extend %pseudo-block;}
@mixin pseudo-inlineblock { @extend %pseudo-inlineblock;}
@mixin pseudo-absolute { @extend %pseudo-absolute;}

.clearfix {
	@include clearfix;
}
/* ====================================== */
/* normalize */
@import "include/ress";
/* ====================================== */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&family=Work+Sans:wght@400;600&display=swap');

@font-face {
	font-family: "MyYuGothicM";
	font-weight: normal;
	src: local("YuGothic-Medium");
}
@font-face {
	font-family: "MyYuGothicM";
	font-weight: bold;
	src: local("YuGothic-Bold");
}
/* ====================================== */
html {
	font-size: 62.5%;
}
body {
	margin: 0;
	padding: 0;
	outline: 0;
	border: 0;
	font-size: 1.6rem;
	color: $color-txt1;
	line-height: 2;
	font-family: $font_gothic;
	@include media(tablet) { line-height: 1.9; }
	@include media(sp) { line-height: 1.8; font-size: 1.4rem; }
}
a {
	opacity: 1;
  color: $color-txt1;
	text-decoration: none;
}
ul, ol {
	margin: 0;
	padding: 0;
	list-style: none;
}
img {
	backface-visibility: hidden;
	vertical-align: bottom;
	max-width: 100%;
}

[data-view*="sp"] { display: none;}
[data-view*="tablet"] { display: none;}
[data-view*="pc"] { display: block;}
[data-view*="pc-inlineblock"] { display: inline-block;}

@include media(tablet) {
	[data-view*="pc"] { display: none;}
	[data-view*="pc-inlineblock"] { display: none;}
	[data-view*="tablet"] { display: block;}
	[data-view*="tablet-inlineblock"] { display: inline-block;}
}
@include media(sp) {
	[data-view*="pc"] { display: none;}
	[data-view*="pc-inlineblock"] { display: none;}
	[data-view*="tablet"] { display: none;}
	[data-view*="tablet-inlineblock"] { display: none;}
	[data-view*="sp"] { display: block;}
	[data-view*="sp-inlineblock"] { display: inline-block;}
}
/* ====================================== */
@import "include/pure";
@import "include/module";
/* ====================================== */
.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 130px;
	background-color: #fff;
	z-index: 2000;
	transition: .3s;
	@include media(tablet) { height: 75px; }

	&-inner {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding-top: 24px;
		@include media(tablet) { padding-top: 0; }
	}
	&-logo {
		display: block;
		position: absolute;
		left: 50px;
		top: 50%;
		transform: translateY(-50%);
		width: 215px;
		img { backface-visibility: visible; }
		@include media(tablet) {
			left: 20px;
			width: 150px;
		}
		@include media(sp) {
			width: 100px;
		}
	}
	&-nav {
		margin-top: auto;
		margin-bottom: 0;
		@include media(tablet) {
			display: none;
			position: absolute;
			top: 0; right: 0;
			width: 45%;
			background-color: #fff;
			opacity: 0;
			transform: translateX(100%);
			transition: .4s;
			z-index: 2001;
			&.is-active { display: block; }
			&.is-visible {
				opacity: 1;
				transform: translateX(0);
			}
		}
		@include media(sp) { width: 70%; }

		&-close {
			display: none;
			opacity: 0;
			position: absolute;
			top: 40px;
			right: 40px;
			width: 40px; height: 40px;
			cursor: pointer;
			z-index: 2002;
			svg {
				width: 40px; height: 40px;
				fill: #fff;
			}
			&.is-active { display: block; }
			&.is-visible { opacity: 1; }

			@include media(tablet) {
				display: block;
				opacity: 1;
				position: absolute;
				top: 20px; left: -50px;
				width: 30px; height: 30px;
				svg {
					width: 30px; height: 30px;
					fill: #fff;
				}
			}
		}
		&-inner {
			@include media(tablet) {
				height: auto;
				max-height: 100vh;
				max-height: 100dvh;
				overflow-y: auto;
			}
		}
		&-main {
			margin-right: 85px;
			text-align: right;
			line-height: 1.4;
			@include media(tablet) {
				position: relative;
				top: auto;
				right: auto;
				transform: translateY(0);
				width: 100%;
				margin-right: 0;
				padding: 10px 20px;
				text-align: left;
			}

			&-cat { //details
				display: inline-block;
				margin-left: vw(40);
				&:first-child { margin-left: 0; }
				@include media(tablet) {
					display: block;
					margin-left: 0;
					border-bottom: 1px solid $color-border1;
				}

				&.__search, &.__lang { margin-left: 25px; }
				&.__search {
					@include media(tablet) { display: none; }
					svg {
						width: 20px; height: 20px;
						fill: $color-red;
						transform: translateY(-2px);
						transition: .3s;
						@include media(tablet) {
							fill: $color-red;
							transform: translateY(0);
						}
					}
				}
				&.__lang {
					@include media(tablet) {
						margin-left: 0;
						border-bottom: none;
					}
					a {
						display: inline-block;
						padding-bottom: 20px;
						color: $color-txt1;
						font-size: 2.5rem;
						vertical-align: middle;
						transition: color .3s;
						&:hover { color: $color-red; }
						@include media(1500px) { font-size: 1.3vw; }
						@include media(tablet) {
							padding: 10px 0;
							font-size: 1.8rem;
							&:hover { color: $color-txt1; }
						}
					}
				}
			}
			&-head { // summary
				position: relative;
				list-style-type: none;
				&::-webkit-details-marker { display: none; }
				svg {
					position: absolute;
					bottom: 5px;
					left: 0; right: 0;
					margin: auto;
					width: 13px; height: 13px;
					fill: $color-red;
					opacity: 0;
				}

				@include media(tablet) {
					&:before, &:after {
						content: '';
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
						background-color: $color-txt1;
					}
					&:before {
						right: 6px;
						width: 2px;
						height: 14px;
					}
					&:after {
						right: 0;
						width: 14px;
						height: 2px;
					}
					svg { display: none; }

					&._noMegamenu {
						&:before, &:after { content: none; }
						svg {
							display: block;
							right: 0; left: auto;
							top: 50%;
							transform: translateY(-50%) rotate(-90deg);
							width: 14px; height: 14px;
							fill: $color-txt1;
							opacity: 1;
						}
					}
				}

				a {
					display: inline-block;
					padding-bottom: 20px;
					color: $color-txt1;
					font-size: 2.5rem;
					vertical-align: middle;
					transition: .3s;
					&:hover { color: $color-red; }
					@include media(1500px) { font-size: 1.3vw; }
					@include media(tablet) {
						display: block;
						padding: 10px 0;
						color: $color-txt1;
						text-shadow: none;
						font-size: 1.8rem;
						[lang="en"] & { font-size: 1.6rem; }
						&:hover { color: $color-txt1; }
					}
				}
			}
		}
		&-megamenu {
			width: 100%;
			position: absolute;
			top: 130px; left: 0;
			background-color: #B60005;
			color: #fff;
			text-align: left;
			overflow: hidden;
			@include media(tablet) {
				position: relative;
				top: auto; left: auto;
				background-color: #fff;
				color: $color-txt1;
			}

			.__inner {
				display: flex;
				max-width: 1300px;
				margin-left: auto;
				margin-right: auto;
				padding: 30px 0px;
				@include media(tablet) {
					max-width: 100%;
					padding: 10px 0 20px;
				}
			}
			.__sec {
				border-right: 1px solid rgba($color: #fff, $alpha: .3);

				&._img {
					width: (244 / 1300) * 100%;
					padding-right: (30 / 1300) * 100%;
					img { width: 100%; }
					@include media(1300px) { display: none; }
				}
				&._menu {
					position: relative;
					width: (724 / 1300) * 100%;
					padding-left: (30 / 1300) * 100%;
					padding-right: (30 / 1300) * 100%;
					@include media(1300px) {
						width: (684 / 1024) * 100%;
						padding-left: (25 / 1024) * 100%;
						padding-right: (25 / 1024) * 100%;
					}
					@include media(tablet) {
						width: 100%;
						padding-left: 0;
						padding-right: 0;
					}

					&:after {
						content: '';
						position: absolute;
						top: 0;
						right: 50%;
						width: 1px;
						height: 100%;
						background-color: rgba($color: #fff, $alpha: .3);
					}
					.__list {
						width: 50%;
						@include media(tablet) { width: 100%; }
					}
				}
				&._pickup {
					width: (332 / 1300) * 100%;
					padding-left: (30 / 1300) * 100%;
					border-right: none;
					a { font-size: 1.6rem; }
					img { width: 100%; }
					@include media(1300px) {
						width: (342 / 1024) * 100%;
						padding-left: (25 / 1024) * 100%;
						padding-right: (25 / 1024) * 100%;
					}
					@include media(tablet) { display: none; }
				}

				.__head {
					margin-bottom: 20px;
					font-size: 2.5rem;
					font-weight: 600;
					@include media(1500px) { font-size: 1.3vw; }
					@include media(tablet) { font-size: 1.8rem; }

					&[data-view=pc_sm] {
						display: none;
						@include media(1300px) { display: block; }
						@include media(tablet) { display: none; }
					}
					a {
						display: block;
						color: #fff;
						transition: opacity .3s;
						&:hover { opacity: .5; }
						@include media(tablet) {
							&:hover { opacity: 1; }
						}
					}
				}
				.__list {
					li {
						display: block;
						& + li {
							margin-top: 15px;
							@include media(tablet) { margin-top: 0; }
						}
						a {
							display: block;
							color: #fff;
							font-size: 1.8rem;
							transition: opacity .3s;
							&:hover { opacity: .5; }
							@include media(1300px) { font-size: 1.4rem; }
							@include media(tablet) {
								padding: 5px 0;
								color: $color-txt1;
								&:hover { opacity: 1; }
							}
						}
					}
					&._inner { // 第4階層
						width: calc(100% - 30px);
						transform: translateX(calc(100% + 60px)) translateY(-22px);
						a {
							font-size: 1.6rem;
							.__sub { font-size: 1.4rem; }
						}
						@include media(1300px) {
							transform: translateX(calc(100% + 60px)) translateY(-18px);
							a {
								font-size: 1.2rem;
								.__sub { font-size: 1.1rem; }
							}
						}
						@include media(tablet) {
							display: none;
						}
					}
				}
			}
		}
		&-sub {
			position: absolute;
			top: 0;
			right: 85px;
			letter-spacing: -.4em;
			@include media(tablet) {
				position: relative;
				top: auto;
				right: auto;
				border-top: 1px solid $color-border1;
				text-align: center;
			}

			&-item {
				display: inline-block;
				height: 30px;
				border-left: 1px solid $color-txt1;
				color: $color-txt1;
				font-size: 1.4rem;
				text-align: center;
				line-height: 30px;
				vertical-align: top;
				letter-spacing: normal;
				cursor: pointer;
				transition: border .3s;
				&:last-child { border-right: 1px solid $color-txt1; }
				@include media(tablet) {
					display: block;
					height: auto;
					border-left: 0;
					color: $color-txt1;
					font-size: 1.8rem;
					&:last-child { border-right: 0; }
					&:hover { color: $color-txt1; }
				}

				&.__group {
					width: 270px;
					@include media(tablet) {
						width: 100%;
						padding: 10px 20px;
						text-align: left;
					}

					span {
						display: block;
						padding: 0 20px;
						transition: .3s;
						@include media(tablet) {
							display: block;
							margin-bottom: 10px;
							padding: 0;
							text-shadow: none;
						}
						@include media(sp) { font-size: 1.4rem; }

						svg {
							position: relative;
							width: 12px;
							height: 12px;
							margin-left: 10px;
							fill: $color-txt1;
							transform: translateY(2px);
							transition: .3s;
							@include media(tablet) { display: none; }
						}
						&:hover {
							color: $color-red;
							@include media(tablet) { color: $color-txt1; }
							svg {
								fill: $color-red;
								filter: none;
							}
						}
						&.is-active {
							background-color: #fff;
							color: $color-txt1;
							text-shadow: none;
							svg {
								fill: $color-red;
								filter: none;
								transform: translateY(2px) rotateX(180deg);
							}
						}
					}
					ul {
						display: none;
						position: absolute;
						top: 30px; left: 0;
						z-index: 2002;
						@include media(tablet) {
							display: block;
							position: relative;
							top: auto; left: auto;
						}

						a {
							display: block;
							width: 271px;
							background-color: #fff;
							border-top: 1px solid $color-border2;
							color: $color-txt1;
							font-size: 1.4rem;
							transition: .3s;
							&:hover { color: $color-red; }

							@include media(tablet) {
								position: relative;
								display: block;
								width: 100%;
								margin-bottom: 10px;
								padding-left: 20px;
								border-top: 0;
								font-size: 1.4rem;
								line-height: 1.2;
								&:before {
									content: '';
									position: absolute;
									top: 50%; left: 2px;
									transform: translateY(-50%);
									display: block;
									width: 10px; height: 1px;
									background-color: $color-red;
								}
								&:hover { color: $color-txt1; }
							}
							@include media(sp) { font-size: 1.1rem; }
						}
					}

				}
				&.__contact {
					padding: 0 20px;
					transition: .3s;
					&:hover { color: $color-red; }
					@include media(tablet) {
						padding: 10px;
						background-color: $color-red;
						color: #fff;
					}
				}
			}
		}
		&-siteSearch {
			display: none;
			opacity: 0;
			position: absolute;
			top: 40vh; left: 0; right: 0;
			width: 60%;
			max-width: 800px;
			margin: auto;
			letter-spacing: -.4em;
			z-index: 2002;
			transition: .4s;
			&.is-active { display: block; }
			&.is-visible { opacity: 1; }

			input {
				width: calc(100% - 60px);
				height: 60px;
				background-color: #fff;
				padding: 10px 20px;
				font-size: 1.6rem;
				outline: none;
				letter-spacing: normal;
				&::placeholder { color: #aaa; }
			}
			button {
				width: 60px; height: 60px;
				background-color: $color-red;
				letter-spacing: normal;
				outline: none;
				line-height: 60px;
				vertical-align: top;
				svg {
					width: 30px; height: 30px;
					fill: #fff;
					vertical-align: middle;
				}
			}
			.__keywords {
				margin-top: 10px;
				li {
					display: inline-block;
					letter-spacing: normal;
					margin-right: 20px;
					a {
						color: #fff;
						text-decoration: underline;
						transition: .3s;
						&:hover { opacity: .7; }
					}
				}
			}
			@include media(tablet) {
				display: block;
				opacity: 1;
				position: relative;
				top: auto;
				width: 100%;
				input {
					width: calc(100% - 60px);
					margin-left: 10px;
					padding: 20px 5px 20px 10px;
					font-size: 1.4rem;
					border-bottom: 1px solid $color-border2;
				}
				button {
					width: 50px; height: 60px;
					background-color: #fff;
					line-height: 50px;
					svg {
						width: 24px; height: 24px;
						fill: $color-red;
					}
				}
				.__keywords {
					padding: 0 10px;
					li {
						display: inline-block;
						margin-left: 5px;
						margin-bottom: 5px;
						margin-right: 0;
						a {
							display: inline-block;
							padding: 0 10px;
							background-color: #f9eeee;
							border-radius: 20px;
							color: $color-red;
							line-height: 1.5;
							transition: .3s;
							&:hover {
								background-color: $color-red;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
	&-code {
		position: absolute;
		top: 37px;
		right: 85px;
		display: inline-block;
		padding: 0 20px;
		border: 1px solid $color-txt1;
		color: $color-txt1;
		font-size: 1.4rem;
		transition: .3s;
		@include media(tablet) { display: none; }
	}
	&-hamburger {
		display: none;

		@include media(tablet) {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: 75px;
			height: 75px;
			text-align: center;

			&:before, &:after {
				content: '';
				position: absolute;
				background-color: #fff;
				box-shadow: 0 0 3px 2px rgba(0,0,0,.2);
				transition: .4s;
			}
			&:before {
				top: 27px;
				left: 30%;
				width: 25px;
				height: 2px;
			}
			&:after {
				bottom: 28px;
				left: 30%;
				width: 25px;
				height: 2px;
			}
			em {
				display: block;
				position: absolute;
				top: 50%;
				left: 30%;
				width: 25px;
				height: 2px;
				margin-top: -2px;
				background-color: #fff;
				box-shadow: 0 0 3px 2px rgba(0,0,0,.2);
				opacity: 1;
				transition: .4s;
			}
		}
	}
	&-overlay {
		display: none;
		position: fixed;
		top: 0; left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.8);
		opacity: 0;
		transition: .4s;
		z-index: 2000;
		&.is-active { display: block; }
		&.is-visible { opacity: 1; }
	}
}
/* ====================================== */
.contents {
	padding-top: 130px;
	padding-bottom: 150px;
	@include media(tablet) {
		padding-top: 75px;
		padding-bottom: 100px;
	}
}
/* ====================================== */
.footer {
	position: relative;
	border-top: 1px solid $color-border1;
	&-pagetop {
		position: fixed;
		display: block;
		bottom: 20px;
		right: 20px;
		width: 50px;
		height: 50px;
		background-color: rgba(255,255,255,.8);
		border: 1px solid $color-red;
		opacity: 0;
		transition: .3s;
		z-index: 1000;
		svg {
			position: absolute;
			top: 50%; left: 0; right: 0;
			transform: translateY(-50%);
			margin: auto;
			width: 16px; height: 16px;
			fill: $color-red;
			transition: .3s;
		}
		&.is-visible {
			opacity: 1;
			&:hover {
				background-color: $color-red;
				svg { fill: #fff; }
			}
		}
		&.is-bottom {
			position: absolute;
			top: 20px;
		}
		@include media(tablet) {
			right: 20px;
			&.is-visible:hover {
				background-color: transparent;
				svg { fill: $color-red; }
			}
		}
	}
	&-inner {
		position: relative;
		padding-top: 30px;
		padding-bottom: 40px;
	}
	&-nav {
		margin-bottom: 20px;
		line-height: 1.2;
		font-size: 1.3rem;
		li {
			display: inline-block;
			margin-right: 5px;
			&:after {
				content: '|';
				display: inline-block;
				margin-left: 5px;
			}
			&:last-child:after { content: none; }
			a {
				transition: .3s;
				&:hover { color: $color-red; }
			}
		}
		@include media(tablet) {
			display: flex;
			flex-wrap: wrap;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-border2;
			&.__last { border-bottom: 0; }
			li {
				margin-right: 0;
				margin-bottom: 15px;
				&:after { content: none; }
			}
		}
		@include media(sp) {
			li a { font-size: 1.2rem; }
		}
	}
	&-bnr {
		margin-top: 30px;
		img { width: 275px; }
		@include media(sp) { text-align: center; }
	}
	.mod-sns {
		position: absolute;
		bottom: 40px;
		right: 50px;
		@include media(tablet) { right: 0; }
		@include media(sp) {
			position: relative;
			bottom: auto; right: auto;
			margin-top: 40px;
			margin-bottom: 20px;
		}
	}
	&-copy {
		padding: 10px;
		background-color: $color-red;
		color: #fff;
		font-size: 1.2rem;
		text-align: center;
	}
}
/* ====================================== */
/* js */
.js {
	&-tab {
		&-content {
			display: none;
			opacity: 0;
			transition: opacity .4s;
			&.is-active { display: block; }
			&.is-visible { opacity: 1; }

			@include media(sp) {
				&.js-toggleBlock {
					opacity: 1;
				}
			}
		}
	}
	&-toggle {
		position: relative;
		cursor: pointer;
		transition: .4s;
		&:hover { opacity: .55; }

		@include media(tablet) {
			&:hover { opacity: 1; }
		}
		@include media(sp) {
			user-select: none;
			padding: 10px;
			border: 1px solid $color-border2;
		}

		&Block {
			display: none;
			& + .js-toggle { margin-top: 20px; }
		}
		&-switch {
			display: block;
			position: absolute;
			top: 48%;
			right: 20px;
			width: 20px;
			height: 2px;
			background-color: $color-red;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: -9px;
				right: 9px;
				width: 2px;
				height: 20px;
				background-color: $color-red;
				transition: .3s;
			}
			.is-active > &:after {
				transform: rotateZ(90deg);
			}
			@include media(sp) {
				right: 4%;
			}
		}
		&.green {
			.js-toggle-switch {
				background-color: $color-green2;
				&:after { background-color: $color-green2; }
			}
		}
		svg {
			width: 16px; height: 16px;
			margin-left: 10px;
			fill: $color-red;
			transform: translateY(2px);
			transition: .3s;
		}
		&.is-active > svg {
			transform: translateY(2px) rotateX(180deg);
		}
	}
}

// Animation
.js-anime {
	&._fade {
		opacity: 0;
		transition: 2.0s opacity;
		&.is-visible {
			opacity: 1;
		}
	}
	&._typing {
		width: 0;
		white-space: nowrap;
		overflow: hidden;
		&.is-visible {
			animation: typing 2s steps(21, end);
			animation-fill-mode: forwards;
		}
	}
	&._bottom {
		opacity: 0;
		transform: translateY(50px);
		&.is-visible {
			animation: bottom 1.5s ease;
			animation-fill-mode: forwards;
		}
	}
	&._left {
		opacity: 0;
		transform: translateX(-50px);
		&.is-visible {
			animation: left 1.5s ease;
			animation-fill-mode: forwards;
		}
	}
	&._list {
		&._bottom {
			._item {
				opacity: 0;
				transform: translateY(50px);
			}
			&.is-visible {
				._item {
					animation: bottom 1.5s ease;
					animation-fill-mode: forwards;
				}
				.__1 { animation-delay: 0; }
				.__2 { animation-delay: .2s; }
				.__3 { animation-delay: .4s; }
				.__4 { animation-delay: .6s; }
				.__5 { animation-delay: .8s; }
				.__6 { animation-delay: 1.0s; }
			}
		}
		&._left {
			._item {
				opacity: 0;
				transform: translateX(-50px);
			}
			&.is-visible {
				._item {
					animation: left 1.5s ease;
					animation-fill-mode: forwards;
				}
				.__1 { animation-delay: 0; }
				.__2 { animation-delay: .2s; }
				.__3 { animation-delay: .4s; }
				.__4 { animation-delay: .6s; }
				.__5 { animation-delay: .8s; }
				.__6 { animation-delay: 1.0s; }
			}
		}
	}
	&.is-visible {
		&.__1 { animation-delay: 0; }
		&.__2 { animation-delay: .2s; }
		&.__3 { animation-delay: .4s; }
		&.__4 { animation-delay: .6s; }
		&.__5 { animation-delay: .8s; }
		&.__6 { animation-delay: 1.0s; }
	}
}

@keyframes typing {
	0% { width: 0; }
	100% { width: auto; }
}
@keyframes bottom {
	0% {
		opacity: 0;
		transform: translateY(50px);
	}
	30% {
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes left {
	0% {
		opacity: 0;
		transform: translateX(-50px);
	}
	30% {
		transform: translateX(10px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
/* ====================================== */
/* ディレクトリ別パーツ */
@import "index";
@import "business";
@import "esg";
@import "recruit";
/* ====================================== */
@include module;
/* ====================================== */