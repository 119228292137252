%svg-common {
	background: url("/common/svg/sprite-bg.svg") no-repeat;
}

.icon-arrow_bottom {
	@extend %svg-common;
	background-position: 0 6.976744186046512%;
}

.icon-arrow_bottom_dims {
	width: 100px;
	height: 100px;
}

.icon-arrow_left {
	@extend %svg-common;
	background-position: 20.792079207920793% 0;
}

.icon-arrow_left_dims {
	width: 100px;
	height: 100px;
}

.icon-arrow_right {
	@extend %svg-common;
	background-position: 40.59405940594059% 0;
}

.icon-arrow_right_dims {
	width: 100px;
	height: 100px;
}

.icon-arrow_top {
	@extend %svg-common;
	background-position: 0 30.232558139534884%;
}

.icon-arrow_top_dims {
	width: 100px;
	height: 100px;
}

.icon-assets {
	@extend %svg-common;
	background-position: 19.801980198019802% 30.232558139534884%;
}

.icon-assets_dims {
	width: 100px;
	height: 100px;
}

.icon-balance {
	@extend %svg-common;
	background-position: 39.603960396039604% 30.232558139534884%;
}

.icon-balance_dims {
	width: 100px;
	height: 100px;
}

.icon-blank {
	@extend %svg-common;
	background-position: 60.396039603960396% 0;
}

.icon-blank_dims {
	width: 100px;
	height: 100px;
}

.icon-blog {
	@extend %svg-common;
	background-position: 60.396039603960396% 23.25581395348837%;
}

.icon-blog_dims {
	width: 100px;
	height: 100px;
}

.icon-car {
	@extend %svg-common;
	background-position: 0 53.48837209302326%;
}

.icon-car_dims {
	width: 100px;
	height: 100px;
}

.icon-check {
	@extend %svg-common;
	background-position: 19.801980198019802% 53.48837209302326%;
}

.icon-check_dims {
	width: 100px;
	height: 100px;
}

.icon-close {
	@extend %svg-common;
	background-position: 39.603960396039604% 53.48837209302326%;
}

.icon-close_dims {
	width: 100px;
	height: 100px;
}

.icon-co2 {
	@extend %svg-common;
	background-position: 59.40594059405941% 53.48837209302326%;
}

.icon-co2_dims {
	width: 100px;
	height: 100px;
}

.icon-cumulative_assets {
	@extend %svg-common;
	background-position: 80.1980198019802% 0;
}

.icon-cumulative_assets_dims {
	width: 100px;
	height: 100px;
}

.icon-download {
	@extend %svg-common;
	background-position: 80.1980198019802% 23.25581395348837%;
}

.icon-download_dims {
	width: 100px;
	height: 100px;
}

.icon-eco {
	@extend %svg-common;
	background-position: 80.1980198019802% 46.51162790697674%;
}

.icon-eco_dims {
	width: 100px;
	height: 100px;
}

.icon-electric {
	@extend %svg-common;
	background-position: 0 76.74418604651163%;
}

.icon-electric_dims {
	width: 100px;
	height: 100px;
}

.icon-excel {
	@extend %svg-common;
	background-position: 19.801980198019802% 76.74418604651163%;
}

.icon-excel_dims {
	width: 100px;
	height: 100px;
}

.icon-house {
	@extend %svg-common;
	background-position: 39.603960396039604% 76.74418604651163%;
}

.icon-house_dims {
	width: 100px;
	height: 100px;
}

.icon-livecamera {
	@extend %svg-common;
	background-position: 59.40594059405941% 76.74418604651163%;
}

.icon-livecamera_dims {
	width: 100px;
	height: 100px;
}

.icon-map {
	@extend %svg-common;
	background-position: 79.20792079207921% 76.74418604651163%;
}

.icon-map_dims {
	width: 100px;
	height: 100px;
}

.icon-movie {
	@extend %svg-common;
	background-position: 100% 0;
}

.icon-movie_dims {
	width: 100px;
	height: 100px;
}

.icon-pdf {
	@extend %svg-common;
	background-position: 100% 23.25581395348837%;
}

.icon-pdf_dims {
	width: 100px;
	height: 100px;
}

.icon-power_plant {
	@extend %svg-common;
	background-position: 100% 46.51162790697674%;
}

.icon-power_plant_dims {
	width: 100px;
	height: 100px;
}

.icon-property {
	@extend %svg-common;
	background-position: 100% 69.76744186046511%;
}

.icon-property_dims {
	width: 100px;
	height: 100px;
}

.icon-search {
	@extend %svg-common;
	background-position: 0 100%;
}

.icon-search_dims {
	width: 100px;
	height: 100px;
}

.icon-solar {
	@extend %svg-common;
	background-position: 19.801980198019802% 100%;
}

.icon-solar_dims {
	width: 100px;
	height: 100px;
}

.icon-swipe {
	@extend %svg-common;
	background-position: 0 0;
}

.icon-swipe_dims {
	width: 105px;
	height: 30px;
}

.icon-tenant {
	@extend %svg-common;
	background-position: 39.603960396039604% 100%;
}

.icon-tenant_dims {
	width: 100px;
	height: 100px;
}

.icon-wind {
	@extend %svg-common;
	background-position: 59.40594059405941% 100%;
}

.icon-wind_dims {
	width: 100px;
	height: 100px;
}

