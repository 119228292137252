@charset "utf-8";
// ====================================== //
// business //
.biz {
	&-idx {
		&-h2 {
			border-bottom: 1px solid $color-txt1;
			&-inner {
				border-bottom: 1px solid $color-txt1;
				padding-bottom: 4px;
			}
			&-ruby {
				display: inline-block;
				&-rp {
					display: block;
					text-align: center;
					font-size: 1.6rem;
					letter-spacing: 1em;
					line-height: 1;
				}
			}
			@include media(sp) {
				&-ruby {
					line-height: 2;
					&-rp {
						font-size: 1.4rem;
					}
				}
			}
		}
		&-core {
			.__top {
				position: relative;
				padding: 20px;
				background: url('/images/business/index/core_top.png') no-repeat;
				background-size: 100% 100%;
				color: $color-red;
				text-align: center;
				line-height: 1.5;
			}
			&-inner {
				margin: 0 40px;
				@include media(tablet) {
					margin: 0 20px;
					.space-u-40 {
						margin-left: -20px;
						.space-u-40-child { padding-left: 20px; }
					}
				}
				@include media(sp) {
					margin: 0 10px;
					.space-u-40 {
						margin-left: -10px;
						.space-u-40-child { padding-left: 10px; }
					}
				}

				div	{
					padding: 0 40px;
					background-color: $color-light-red;
					@include media(tablet) { padding: 0 20px; }
					@include media(sp) { padding: 0 10px; }

					.__item {
						display: block;
						padding: 100px 20px;
						background-color: #fff;
						border: 1px solid #fff;
						color: $color-red;
						text-align: center;
						transition: .3s;
						.ua-ie & { height: 430px; }

						&:hover {
							border-color: $color-red;
							.mod-button {
								background-color: $color-red;
								color: #fff;
							}
						}
						.mod-button { max-width: 100%; }
						.__txt2 { font-size: 2.4rem; }
						.__title {
							display: block;
							margin-left: auto;
							margin-right: auto;
							width: 240px;
							max-width: 100%;
							margin-top: 20px;
							padding: 10px 20px;
							background-color: $color-red;
							color: #fff;
						}
						&.green {
							color: $color-green2;
							.__title {
								background-color: $color-green2;
							}
							&:hover {
								border-color: $color-green2;
								.mod-button {
									background-color: $color-green2;
								}
							}
						}
						@include media(tablet) {
							padding: 40px 10px;
							.__title { padding: 10px; }
							&:hover {
								border-color: #fff;
								.mod-button {
									background-color: #fff;
									color: $color-red;
								}
							}
							&.green {
								&:hover {
									border-color: #fff;
									.mod-button {
										background-color: #fff;
										color: $color-green2;
									}
								}
							}
						}
						@include media(sp) {
							padding: 20px 5px;
							.__txt1 {
								font-size: 1.2rem;
								line-height: 1.5;
							}
							.__txt2 {
								margin-top: 10px;
								font-size: 1.6rem;
								line-height: 1.5;
							}
							.__title {
								width: 100%;
								padding: 10px 2px;
								font-size: 1.1rem;
								line-height: 1.5;
							}
						}
					}
				}
			}
			.__bottom {
				position: relative;
				padding: 20px;
				background: url('/images/business/index/core_bottom.png') repeat-y;
				background-size: 100% auto;
				div {
					width: 400px;
					max-width: 100%;
					margin: 0 auto;
					padding: 20px;
					background-color: #fff;
					color: $color-red;
					text-align: center;
					line-height: 1.5;
				}
				@include media(sp) {
					padding: 10px;
					div {
						padding: 10px;
					}
				}
			}
		}
		&-sustainable {
			padding: 50px 60px;
			background-color: #fff;
			box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);
			&-h3 {
				margin-bottom: 45px;
				text-align: center;
				font-size: 2.0rem;
				font-weight: normal;
				line-height: 1;
				span { font-size: 3.6rem; }
			}
			&-img { padding-right: 20px; }
			&-ul {
				margin-top: 50px;
				&-li {
					display: flex;
					align-items: center;
					margin-top: 30px;
					&:first-child {
						margin-top: 0;
					}
					&-txt {
						padding-left: 20px;
						line-height: 1.5;
					}
				}
			}
			@include media(tablet) {
				padding: 50px 40px;
				&-ul {
					&-li {
						&-img { width: 100px; }
					}
				}
			}
			@include media(sp) {
				padding: vw(50px, $sp) vw(40px, $sp);
				&-img { padding-right: 0; }
				&-h3 {
					margin-bottom: vw(60px, $sp);
					font-size: 1.5rem;
					span { font-size: 2.7rem; }
				}
				&-ul {
					margin-top: vw(90px, $sp);
					&-li {
						display: block;
						margin-top: vw(50px, $sp);
						&-img { width: vw(240px, $sp); }
						&-txt {
							margin-top: vw(20px,$sp);
							padding-left: 0;
							line-height: 1.8;
						}
					}
				}
			}
		}
	}
	&-sustainable {
		&-lead {
			position: relative;
			padding: 60px;
			background-color: #fff;
			text-align: center;
			font-size: 6.0rem;
			font-family: $font-alphabet;
			font-weight: bold;
			line-height: 1.2;
			img {
				display: inline-block;
				width: 120px;
				margin-right: 20px;
				vertical-align: middle;
			}
			span {
				vertical-align: middle;
			}
			@include media(sp) {
				padding: vw(50px,$sp) vw(60px,$sp) vw(50px,$sp) 100px;
				font-size: 3.6rem;
				img {
					position: absolute;
					left: 20px;
					top: 50%;
					transform: translateY(-50%);
					width: 70px;
				}
			}
		}
		&-revers {
			@include media(sp) {
				flex-flow: column-reverse;
			}
		}
		&-tbl {
			&-kwh {
				color: $color-green2;
				font-size: 3.0rem;
				font-family: $font-alphabet;
				font-weight: bold;
				@include media(sp) {
					font-size: 2.4rem;
				}
			}
			&-swipe {
				width: 50px;
				vertical-align: middle;
				@include media(sp) {
					width: vw(100px,$sp);
				}
			}
		}
		&-detail {
			&-head {
				font-weight: normal;
				&-label {
					display: inline-block;
					min-width: 120px;
					margin-bottom: 20px;
					border: 1px solid $color-txt1;
					padding: 5px;
					text-align: center;
					line-height: 1;
					font-size: 1.3rem;
				}
				&-name {
					display: block;
					font-size: 2.4rem;
					line-height: 1.5;
					&-en {
						display: block;
						margin-top: 15px;
						font-family: $font-alphabet;
						font-size: 1.6rem;
						line-height: 1.2;
					}
				}
				@include media(sp) {
					&-label {
						min-width: vw(180px,$sp);
						margin-bottom: vw(20px,$sp);
						font-size: 1.0rem;
					}
					&-name {
						font-size: 1.8rem;
						&-en {
							margin-top: vw(15px,$sp);
							font-size: 1.2rem;
						}
					}
				}
			}
			.mod-button {
				>.livecameraIcon {
					width: 26px;
					height: 26px;
				}
			}
			&-notice {
				padding: 25px 30px;
				border: 1px solid $color-gray;
			}
			&-tbl {
				box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);
				.mod-tbl02 {
					height: 100%;
				}
				@include media(sp) {
					box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);
				}
			}
			@include media(sp) {
				.idx-highlight-item {
					.mod-count {
						margin-right: 5px;
					}
				}
			}
		}
		&-eco {
			&-block {
				padding: 30px;
				box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15);
				&._map { height: 100%; }
				+ .biz-sustainable-eco-block { margin-top: 50px; }

				.js-totalEnergy, .js-totalCo2 { line-height: 0.9; }

				&._todayEco { height: 320px; }

				&-icon {
					padding-right: 30px;
					svg {
						fill: $color-green2;
						&.solar { width: 45%; }
						&.wind1 { width: 45%; margin-left: 5%; }
						&.wind2 { width: 59%; }
					}
				}
				&-txt {
					font-size: 2.0rem;
					vertical-align: baseline;
					line-height: 1;
				}
				&-num {
					color: $color-green2;
					font-size: 6.8rem;
					font-family: $font-alphabet;
					font-weight: bold;
				}
				&-meter {
					margin-top: 20px;
					padding: 30px 40px 0;
					border-top: 1px solid $color-gray;
					.icon {
						width: 100%;
						height: 40px;
						fill: $color-gray2;
					}
					.is-active .icon { fill: $color-green2; }
					&-txt {
						line-height: 1.5;
						.icon {
							width: 20px;
							height: 20px;
							margin-right: 20px;
							fill: $color-green2;
							vertical-align: middle;
						}
					}
				}
				@include media(sp) {
					padding: vw(30px,$sp);
					+ .biz-sustainable-eco-block { margin-top: 20px; }
					&._todayEco { height: auto; }
					&-txt { font-size: 1.5rem; }
					&-num { font-size: 5.0rem; }
					&-icon {
						padding-right: vw(30px,$sp);
						svg {
							&.solar { width: 60px; height: 60px; }
							&.wind1 { width: 60px; height: 60px; margin-top: 10px; }
							&.wind2 { width: 80px; height: 80px; }
						}
					}
					&-meter {
						margin-top: vw(20px,$sp);
						padding: vw(30px,$sp) 0 0;
						&-txt {
							.icon {
								width: vw(30px,$sp);
								height: vw(30px,$sp);
								margin-right: vw(20px,$sp);
							}
						}
					}
				}
			}
			&-head {
				&-icon {
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-right: 10px;
					vertical-align: middle;
				}
				@include media(sp) {
					&-icon {
						width: vw(30px,$sp);
						height: vw(30px,$sp);
						margin-right: vw(10px,$sp);
					}
				}
			}
			&-plant {
				position: relative;
				font-size: 2.0rem;
				text-align: center;
				line-height: 1.5;
				&:before {
					position: absolute;
					display: block;
					content: '';
					top: calc(50% - 1px);
					left: 0;
					width: 100%;
					height: 2px;
					background-color: $color-txt1;
				}
				&-inner {
					position: relative;
					display: inline-block;
					background-color: #fff;
					padding: 0 35px;
				}
				&List {
					&-li {
						margin-top: 40px;
						&:first-child { margin-top: 0; }
						.mod-head03 { margin-bottom: 0; }
					}
				}
				@include media(sp) {
					font-size: 1.5rem;
					&:before { height: 1px; }
					&-inner { padding: 0 vw(35px,$sp); }
					&List {
						&-li { margin-top: vw(40px,$sp); }
					}
				}
			}
			&-map {
				position: relative;
				width: 100%;
				height: 600px;
				img {
					outline: none;
					&[alt=""] { outline: none; }
				}
				#map-canvas {
					width: 100%;
					height: 100%;
				}
				&-chip {
					position: absolute;
					top: 100px;
					left: 100px;
					width: 350px;
					padding: 30px 20px;
					border: 1px solid $color-green;
					background-color: #fff;

					.chip-close {
						position: absolute;
						top: 10px; right: 10px;
						width: 18px; height: 18px;
						fill: $color-txt1;
						cursor: pointer;
					}
					a {
						display: block;
						font-size: 1.8rem;
						line-height: 1.5;
					}
					p {
						margin-top: 10px;
						line-height: 1.5;
					}
				}
				@include media(tablet) {
					&-chip {
						top: 20px; left: 20px;
					}
				}
				@include media(sp) {
					height: 400px;
					&-chip {
						top: 10px; left: 10px;
						width: 200px;
						padding: 10px 30px 10px 10px;
						a { font-size: 1.6rem; }
					}
				}
			}
			&-live {
				position: relative;
				width: 100%;
				height: 100%;
				&-loader {
					position: absolute;
					top: 0; bottom: 0; left: 0; right: 0;
					margin: auto;
					z-index: 0;
				}
				&-img {
					position: relative;
					z-index: 1;
				}
			}
		}
		&-re {
			&-ul {
				li {
					position: relative;
					padding-left: 25px;
					margin-top: 10px;
					&:first-child { margin-top: 0; }
					.icon {
						position: absolute;
						top: 8px;
						left: 0;
						width: 15px;
						height: 15px;
					}
				}
			}
		}
	}
	&-strength {
		&-pager {
			padding: 25px 0;
			background-color: $color-light-red;
			&-a {
				display: inline-block;
				color: $color-red;
				font-size: 1.6rem;
				.icon {
					width: 18px;
					height: 18px;
					fill: $color-red;
					vertical-align: middle;
					line-height: 1;
					&.prev { margin-right: 15px; }
					&.next { margin-left: 15px; }
				}
			}
			@include media(sp) {
				&-a {
					font-size: 1.4rem;
					span { vertical-align: middle; }
					.icon {
						width: 14px;
						height: 14px;
						&.prev { margin-right: vw(5px,$sp); }
						&.next { margin-left: vw(5px,$sp); }
					}
				}
			}
		}
		&-case {
			display: flex;
			flex-flow: row wrap;
			&-li {
				position: relative;
				margin-right: 20px;
				padding-right: 20px;
				&:before {
					position: absolute;
					display: block;
					content: '';
					right: 0;
					top: calc(50% - 7px);
					width: 1px;
					height: 14px;
					background-color: $color-txt1;
				}
				&:last-child {
					margin-right: 0;
					&:before { display: none; }
				}
			}
			&-detail {
				background-color: $color-light-red;
				padding: 40px 30px;
			}
			&-imgWrap {
				display: flex;
				justify-content: center;
				.icon {
					width: 60px;
					margin: 0 20px;
				}
			}
			@include media(sp) {
				&-li {
					display: block;
					margin-right: 0;
					padding-right: 0;
					font-size: 1.4rem;
					&:before { content: none; }
				}
				&-detail {
					padding: vw(40px,$sp);
				}
				&-imgWrap {
					.icon {
						width: vw(60px,$sp);
						margin: 0 vw(20px,$sp);
					}
				}
			}
		}
	}
	&-residence {
		.idx-highlight-item {
			justify-content: center;
			.__icon {
				position: static;
				width: 45px;
				transform: translateY(0);
				> svg {
					width: 45px;
				}
			}
			.__data {
				width: auto;
				min-width: auto;
				margin: 0 40px;
				+.__data {
					margin-left: 0;
				}
				.mod-count {
					margin: 0 10px;
				}
			}
			@media screen and (max-width: 1260px) {
				display: flex;
			}
			@include media(tablet) {
				display: block;
				padding: 20px 10px;
				.__data {
					+.__data {
						margin-left: 40px;
					}
				}
			}
		}
		&-head {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&-map {
			position: relative;
			width: per(700px,1200px);
			text-align: center;
			&-wrap {
				display: flex;
				justify-content: space-between;
			}
			&-img {
				position: relative;
				display: inline-block;
				> span {
					position: absolute;
					border-radius: 50%;
					background-color: $color-red;
				}
			}
			.tokyo_adachi {
				top: per(112px, 722px);
				left: per(455px, 700px);
			}
			.tokyo_arakawa {
				top: per(169px, 722px);
				left: per(414px, 700px);
			}
			.tokyo_bunkyo {
				top: per(246px, 722px);
				left: per(359px, 700px);
			}
			.tokyo_chiyoda {
				top: per(328px, 722px);
				left: per(364px, 700px);
			}
			.tokyo_chuo {
				top: per(322px, 722px);
				left: per(417px, 700px);
			}
			.tokyo_edogawa {
				top: per(315px, 722px);
				left: per(614px, 700px);
			}
			.tokyo_itabashi {
				top: per(130px, 722px);
				left: per(349px, 700px);
			}
			.tokyo_katsushika {
				top: per(185px, 722px);
				left: per(570px, 700px);
			}
			.tokyo_kita {
				top: per(148px, 722px);
				left: per(324px, 700px);
			}
			.tokyo_koto {
				top: per(361px, 722px);
				left: per(501px, 700px);
			}
			.tokyo_meguro {
				top: per(430px, 722px);
				left: per(250px, 700px);
			}
			.tokyo_minato {
				top: per(413px, 722px);
				left: per(339px, 700px);
			}
			.tokyo_nakano {
				top: per(254px, 722px);
				left: per(197px, 700px);
			}
			.tokyo_nerima {
				top: per(181px, 722px);
				left: per(108px, 700px);
			}
			.tokyo_ota {
				top: per(610px, 722px);
				left: per(270px, 700px);
			}
			.tokyo_setagaya {
				top: per(450px, 722px);
				left: per(127px, 700px);
			}
			.tokyo_shibuya {
				top: per(373px, 722px);
				left: per(264px, 700px);
			}
			.tokyo_shinagawa {
				top: per(515px, 722px);
				left: per(315px, 700px);
			}
			.tokyo_shinjuku {
				top: per(300px, 722px);
				left: per(295px, 700px);
			}
			.tokyo_suginami {
				top: per(313px, 722px);
				left: per(110px, 700px);
			}
			.tokyo_sumida {
				top: per(276px, 722px);
				left: per(462px, 700px);
			}
			.tokyo_taito {
				top: per(253px, 722px);
				left: per(426px, 700px);
			}
			.tokyo_toshima {
				top: per(222px, 722px);
				left: per(287px, 700px);
			}
			.__01 {
				width: 10px;
				height: 10px;
			}
			.__02 {
				width: 16px;
				height: 16px;
			}
			.__03 {
				width: 21px;
				height: 21px;
			}
			.__04 {
				width: 27px;
				height: 27px;
			}
			@include media(tablet) {
				width: 100%;
				&-wrap {
					display: block;
				}
			}
			@include media(sp) {
				.__01 {
					width: 5px;
					height: 5px;
				}
				.__02 {
					width: 8px;
					height: 8px;
				}
				.__03 {
					width: 11px;
					height: 11px;
				}
				.__04 {
					width: 14px;
					height: 14px;
				}
			}
		}
		&-tbl {
			width: per(450px,1200px);
			tr {
				th, td {
					border-right: 1px solid $color-gray;
					text-align: center;
					&:last-child {
						border-right: none;
					}
				}
				td {
					padding: 5px 18px;
				}
			}
			@include media(tablet) {
				width: 100%;
				margin-top: 40px;
			}
		}
		&-tab {
			margin-bottom: 70px;
			span {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 70px;
				margin-top: 0;
				background-color: $color-light-gray;
				border-bottom-color: $color-border2;
				&:before {
					position: absolute;
					content: '';
					left: 50%;
					bottom: -8px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 8px 7px 0 7px;
					border-color: $color-red transparent transparent transparent;
					opacity: 0;
					transform: translateX(-50%);
					transition: opacity .3s;
				}
				&.is-active {
					background-color: $color-light-red;
					&:before { opacity: 1; }
				}
			}
			&-content {
				.mod-head03 {
					display: flex;
					align-items: center;
					justify-content: space-between;
					.__right {
						font-size: 1.6rem;
					}
				}
				@include media(sp) {
					.mod-head03 {
						.__right {
							font-size: 1.4rem;
						}	
					}
				}
			}
			&-count {
				text-align: center;
				&-item {
					margin-left: 25px;
					&:first-child {
						margin-left: 0;
					}
					.mod-count {
						font-size: 4.0rem;
					}
				}
			}
			@include media(sp) {
				margin-bottom: 35px;
			}
		}
		&-anchor {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: center;
			gap: 10px 40px;
			&-a {
				text-decoration: underline;
				transition: .3s;
				svg {
					width: 10px;
					height: 10px;
					margin-left: 5px;
					vertical-align: middle;
					fill: #707070;
					transform: translateY(-2px);
				}
				&:hover {
					color: $color-red;
				}
			}
		}
		&-list {
			margin-top: -60px;
			margin-left: -20px;
			> li {
				padding-top: 60px;
				padding-left: 20px;
			}
			&-item {
				display: block;
				.__img {
					position: relative;
					aspect-ratio: 7/10;
					overflow: hidden;
					> img {
						min-height: 100%;
						min-width: 100%;
						object-fit: cover;
					}
					.__tagWrap {
						position: absolute;
						display: flex;
						flex-flow: row wrap;
						gap: 5px;
						.__tag {
							display: block;
							padding: 5px 10px;
							background-color: $color-red;
							color: #fff;
							font-size: 1.2rem;
							line-height: 1;
						}
					}
				}
				.__name {
					margin-top: 15px;
					line-height: 1.5;
					font-feature-settings: "palt";
				}
				.__date {
					margin-top: 10px;
					color: $color-txt2;
					font-size: 1.4rem;
					line-height: 1.5;
				}
			}
		}
		&-selectWrap {
			display: flex;
			align-items: center;
			justify-content: center;
			.mod-pulldown-wrap {
				display: inline-block;
				width: 300px;
				margin-bottom: 0;
				.mod-pulldown {
					width: 300px;
					&.is-active {
						z-index: 100;
					}
				}
				+.mod-pulldown-wrap {
					margin-left: 15px;
				}
			}
			&-reset {
				margin-left: 20px;
				cursor: pointer;
			}
			@include media(tablet) {
				display: block;
				.mod-pulldown-wrap {
					display: block;
					margin-left: auto;
					margin-right: auto;		
					+.mod-pulldown-wrap {
						margin-left: auto;
						margin-top: 15px;
					}
				}
				&-reset {
					margin-left: auto;
					margin-top: 20px;
					text-align: center;
				}
			}
			@include media(sp) {
				.mod-pulldown-wrap {
					padding-bottom: 50px;
				}
			}
		}
		&-list {
			&-tbl {
				tr {
					th, td {
						border-right: 1px solid $color-gray;
						text-align: center;
						&.width-u-12 {
							width: 12%;
						}
						&.width-u-18 {
							width: 18%;
						}
						&:last-child {
							border-right: none;
						}
					}
				}
				@include media(tablet) {
					white-space: nowrap;
				}
			}
		}
		&-detail {
			&-name {
				margin-bottom: 40px;
				font-size: 2.4rem;
				font-weight: normal;
				line-height: 1.5;
				@include media(sp) {
					margin-bottom: 20px;
					font-size: 2.0rem;
				}
			}
			&-img {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid $color-border2;
				aspect-ratio: 1/1;
				overflow: hidden;
				> img {
					min-height: 100%;
					min-width: 100%;
					object-fit: cover;
				}
				.__modal {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 40px;
					height: 40px;
					right: 20px;
					bottom: 20px;
					background-color: rgba(0,0,0,.5);
					border-radius: 50%;
					svg {
						width: 20px;
						height: 20px;
						fill: #fff;
					}
				}
				@include media(tablet) {
					cursor: pointer;
				}
			}
			&-slider {
				.slick-dots {
					display: flex;
					align-items: center;
					gap: 20px;
					margin-top: 30px;
					> li {
						width: calc((100% - 80px)/5);
						cursor: pointer;
						figure {
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px solid $color-border2;
							overflow: hidden;
							aspect-ratio: 1/1;
							transition: border-color .4s;
							> img {
								max-height: 100%;
								max-width: 100%;
								object-fit: cover;
							}
						}
						&.slick-active {
							figure {
								border-color: $color-red;
							}
						}
					}
				}
				@include media(sp) {
					.slick-dots {
						gap: 10px;
						margin-top: 15px;
						> li {
							width: calc((100% - 40px)/5);
						}
					}
				}
			}
			.mod-modal {
				position: relative;
				width: auto;
				max-width: 90%;
				padding: 0;
				background-color: transparent;
				box-shadow: none;
				&-overlay {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				&-closeBtn {
					position: absolute;
					width: 40px;
					height: 40px;
					padding: 10px;
					right: -20px;
					top: -20px;
					background-color: $color-border2;
					border-radius: 50%;
				}
				@include media(sp) {
					&-closeBtn {
						right: -10px;
						top: -10px;
					}	
				}
			}
		}
		.mod-pulldown-body {
			li {
				span {
					display: block;
					padding: 9px 20px;
					background-color: #fff;
					border-left: 1px solid $color-border2;
					border-right: 1px solid $color-border2;
					border-bottom: 1px dotted $color-border2;
					font-size: 1.5rem;
					transition: .4s;
					&:hover {
						background-color: $color-red;
						color: #fff;
					}
				}
				&:last-child span {
					border-bottom-style: solid;
				}
			}
			@include media(tablet) {
				li span {
					&:hover {
						background-color: #fff;
						color: $color-txt1;
					}
				}
			}
			@include media(sp) {
				li {
					a {
						padding: 10px 20px;
						font-size: 1.4rem;
					}
				}
			}
		}
	}
}
