@charset "utf-8";
// ============================================================
// 設定
// ============================================================
// SVG
$experimental-support-for-svg: true;
$experimental-support-for-pie: false;

// ベンダープレフィックス サポート
@mixin exp($property, $value,
	$webkit	: $experimental-support-for-webkit,
	$moz		: $experimental-support-for-mozilla,
	$ms			: $experimental-support-for-microsoft,
	$o			: $experimental-support-for-opera,
	$official	: true
) {
	@if $webkit	and $experimental-support-for-webkit	{ -webkit-#{$property}: $value;}
	@if $moz	and $experimental-support-for-mozilla		{ -moz-#{$property}: $value;}
	@if $ms		and $experimental-support-for-microsoft	{ -ms-#{$property}: $value;}
	@if $o		and $experimental-support-for-opera			{ -o-#{$property}: $value;}
	@if $official	{ #{$property}: $value;}
}

// ブレークポイント定義
$break-tablet: 1024px;
$break-sp: 767px;

// 横幅定義
$width: 1024px;
$pc: 1600px;
$pc_num: 1600;
$tablet: 768px;
$tablet_num: 768;
$sp: 640px;
$sp_num: 640;

// 色定義
$color-txt1: #000;
$color-txt2: #999;

$color-border1: #333;
$color-border2: #ccc;

$color-red: #b60005;
$color-blue: #3772a6;
$color-green: #116715;
$color-green2: #158c0c;
$color-orange: #ff7a21;
$color-gray: #ddd;
$color-gray2: #aaa;

$color-light-blue: #0670c8;
$color-light-green: #009f00;
$color-light-green2: #e3f1e2;
$color-light-red: #f9eeee;
$color-light-gray: #f9f9f9;

// 文字定義
$font_alphabet: 'Work Sans', 'Times New Roman', sans-serif;
$font_gothic: 'Noto Sans JP', MyYuGothicM, YuGothic, Meiryo, sans-serif;
$font_mincho: '游明朝', YuMincho, 'HGS明朝E', Meiryo, sans-serif;

// ============================================================
