@charset "utf-8";
// ====================================== //
// index //
.idx {
	&-head {
		padding-top: 150px;
		margin-bottom: 60px;
		text-align: center;
		font-size: 1.6rem;
		line-height: 1.2;
		letter-spacing: 2px;
		.__main {
			display: block;
			margin-top: 10px;
			font-size: 6.0rem;
			font-weight: 700;
			&.ff_gothic { font-size: 4.0rem; }
			[lang="en"] & { font-size: 4.0rem; }
		}
		@include media(tablet) {
			padding-top: 80px;
			margin-bottom: 40px;
			line-height: 1;
			.__main {
				font-size: 5.0rem;
				&.ff_gothic { font-size: 3.8rem; }
			}
		}
		@include media(sp) {
			padding-top: 80px;
			margin-bottom: 40px;
			font-size: 1.0rem;
			line-height: 1;
			.__main {
				font-size: 4.0rem;
				&.ff_gothic { font-size: 2.8rem; }
			}
		}
	}
	&-main {
		position: relative;
		overflow: hidden;
		.__inner { position: relative; }

		&-text {
			position: absolute;
			top: 45%;
			left: 0; right: 0;
			transform: translateY(-50%);
			width: 100%;
			margin: 0 auto;
			text-align: center;
			color: #fff;
			z-index: 10;
			figure {
				display: inline-block;
				width: per(870px,1600px);
			}
			.mod-button {
				margin-top: 30px;
				box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2), 0 0 2px 2px rgba(0, 0, 0, 0.2) inset;
				text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6), 0px 0px 5px rgba(0, 0, 0, 0.6);
				z-index: 500;
				&:hover {
					box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
					text-shadow: none;
				}
			}
			@include media(sp) {
				top: 50%;
				figure {
					width: 90%;
				}
			}
		}
		&-slide {
			.slick-dots {
				position: absolute;
				bottom: 150px;
				left: 0;
				display: flex;
				flex-flow: row wrap;
				align-content: flex-start;
				justify-content: center;
				text-align: center;
				padding: 0;
				margin: 0;
				width: 100%;
				@include media(tablet) {
					bottom: 20px;
				}
				li {
					position: relative;
					display: block;
					height: 8px;
					width: 8px;
					margin: 0 10px;
					padding: 0;
					cursor: pointer;
					button {
						border: 0;
						background: #fff;
						display: block;
						height: 8px;
						width: 8px;
						outline: none;
						line-height: 0;
						font-size: 0;
						color: transparent;
						padding: 0;
						cursor: pointer;
						border-radius: 5px;
					}
					&.slick-active {
						button {
							background: $color-red;
						}
					}
				}
			}
		}
		&-info {
			position: relative;
			width: 100%;
			padding: 20px 0;
			border-bottom: 1px solid $color-border2;
			background-color: #fff;
			font-weight: 700;
			.__inner {
				display: flex;
				align-items: center;
			}
			span {
				width: 110px;
				font-size: 1.8rem;
				[lang="en"] & { width: 130px; }
			}
			ul {
				width: calc(100% - 110px - 340px);
				line-height: 1.5;
				[lang="en"] & { width: calc(100% - 130px - 340px); }
				li {
					position: relative;
					margin-bottom: 10px;
					padding-left: 110px;
					&:last-child { margin-bottom: 0; }
					time {
						position: absolute;
						left: 0;
						width: 110px;
					}
					a {
						transition: .3s;
						&:hover { color: $color-red; }
					}
					svg {
						width: 18px; height: 18px;
						margin-left: 8px;
						fill: $color-red;
						transform: translateY(2px);
						transition: .3s;
					}
				}
			}
			@include media(tablet) {
				.__inner {
					flex-wrap: wrap;
				}
				span {
					width: 100%;
					margin-bottom: 20px;
					text-align: center;
				}
				ul {
					width: 100%;
					li a:hover { color: $color-txt1; }
				}
			}
			@include media(sp) {
				ul {
					li {
						padding-left: 0;
						time {
							position: relative;
							display: block;
						}
						a {
							display: block;
						}
					}
				}
			}
		}
		&-search {
			position: absolute;
			bottom: -350px; right: -375px;
			width: 750px;
			height: 700px;
			padding-top: 100px;
			padding-right: 395px;
			background-color: #fff;
			border: 10px solid $color-red;
			border-radius: 50%;
			box-shadow: 0 0 10px 5px rgba(0,0,0,.2);
			text-align: right;
			z-index: 501;
			&-form {
				width: 250px;
				margin: 20px 0 30px auto;
				border-bottom: 1px solid $color-red;
				input {
					width: calc(100% - 40px);
					padding: 5px;
					outline: none;
					&::placeholder { color: #aaa; font-family: $font_alphabet;}
				}
				button {
					outline: none;
					padding: 5px;
					svg {
						width: 18px; height: 18px;
						fill: $color-red;
						vertical-align: middle;
					}
				}
			}
			.__keywords {
				li {
					display: inline-block;
					margin-left: 5px;
					margin-bottom: 10px;
					a {
						display: inline-block;
						padding: 0 10px;
						background-color: #f9eeee;
						border-radius: 20px;
						color: $color-red;
						line-height: 1.5;
						transition: .3s;
						&:hover {
							background-color: $color-red;
							color: #fff;
						}
					}
				}
			}
			.__close { display: none; }

			@include media(tablet) {
				bottom: 0; right: 0;
				width: 100%;
				height: auto;
				padding: 20px;
				border: 0;
				border-bottom: 1px solid $color-border2;
				border-radius: 0;
				box-shadow: none;
				text-align: center;
				transition: .4s;
				transform: translateX(100%);
				&.is-active { transform: translateX(0); }

				form {
					width: 80%;
					margin: 20px auto 30px;
					input { width: calc(100% - 40px); }
				}
				.__close {
					position: absolute;
					top: 0; right: 0;
					display: block;
					width: 40px;
					height: 40px;
					padding: 10px;
					background-color: $color-red;
					svg {
						width: 20px; height: 20px;
						fill: #fff;
					}
				}
			}

			&-spNav {
				display: none;
				@include media(tablet) {
					position: absolute;
					bottom: 8px; right: 8px;
					display: block;
					width: 90px;
					height: 90px;
					padding-top: 15px;
					background-color: #fff;
					border-radius: 50%;
					box-shadow: 0 0 10px 5px rgba(0,0,0,.2);
					line-height: 1.3;
					text-align: center;
					svg {
						width: 22px; height: 22px;
						fill: $color-red;
					}
				}
			}
		}
	}
	&-brandBanner {
		position: fixed;
		top: 240px; right: 77px;
		z-index: 1000;
		transition: .3s;
		opacity: 0;
		pointer-events: none;
		&.is-visible {
			opacity: 1;
			pointer-events: all;
			&:hover { opacity: .7; }
		}
		@include media(tablet) {
			position: relative;
			top: auto; right: auto;
			opacity: 1;
			margin-top: 250px;
			pointer-events: all;
		}
		@include media(sp) {
			margin-top: 60px;
		}
	}
	&-lead {
		position: relative;
		h2, p {
			position: relative;
			z-index: 500;
		}
		p {
			font-size: 2.0rem;
			line-height: 2.5;
			text-align: center;
		}
		.__logo {
			position: absolute;
			top: 110px;
			left: 130px;
			width: vw(300);
			max-width: 360px;
			opacity: .1;
			z-index: 100;
		}
		@include media(tablet) {
			.__logo {
				top: 50px;
				left: 0;
				width: vw(300, tablet);
			}
		}
		@include media(sp) {
			.__logo {
				left: 20px;
				width: vw(300, sp);
			}
			p {
				font-size: 1.6rem;
				line-height: 1.8;
				text-align: left;
			}
		}
	}
	&-business {
		&-list {
			display: flex;
		}
		&-item {
			width: 33.3333%;
			border-right: 1px solid $color-border2;
			text-align: center;
			&:last-child { border-right: 0; }

			img { width: 130px; }
			.__title {
				margin: 40px 0 10px;
				font-weight: 700;
				font-size: 2.4rem;
				span { font-size: 2.0rem; }
			}
			.mod-button {
				margin-top: 30px;
			}
		}
		@include media(tablet) {
			&-item {
				.__title {
					font-size: 2.0rem;
					span { font-size: 1.8rem; }
				}
			}
		}
		@include media(sp) {
			&-list {
				display: flex;
				flex-wrap: wrap;
			}
			&-item {
				display: flex;
				width: 100%;
				margin-bottom: 40px;
				border-right: 0;
				&:last-child { margin-bottom: 0; }

				figure { width: 110px; }
				._txt {
					width: calc(100% - 110px);
					padding-left: 20px;
					text-align: left;
				}
				.__title {
					margin: 0 0 10px;
					font-size: 1.8rem;
					span { font-size: 1.6rem; }
				}
				.mod-button {
					width: 130px; height: 40px;
					margin-top: 10px;
					margin-left: 0;
					font-size: 1.4rem;
				}
			}
		}
	}
	&-growth {
		&-list {
			li {
				border-right: 1px solid $color-border2;
				&:nth-child(3n), &:last-child { border-right: 0; }
				a {
					display: block;
					text-align: center;
				}
				figcaption { padding: 20px 20px 40px; }
			}
		}
		@include media(tablet) {
			&-list li {
				&:nth-child(3n) { border-right: 1px solid $color-border2; }
				&:nth-child(2n) { border-right: 0; }
				figcaption {
					padding: 10px 10px 20px;
					font-size: 1.2rem;
				}
			}
		}
	}
	&-ir {
		@include media(tablet) {
			.__banner { margin-top: 20px; }
		}
	}
	&-highlight {
		background: url('/images/index/highlight_bg.jpg') no-repeat;
		background-position: 0 0;
		background-size: 540px;

		&-item {
			position: relative;
			display: flex;
			align-items: center;
			padding: 40px 10px 40px 90px;
			background-color: #fff;
			box-shadow: 0 0 10px 5px rgba(0,0,0,.1);
			font-size: 1.8rem;
			text-align: center;
			.__icon {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100px;
				text-align: right;
				svg {
					height: 45px;
					width: 90px;
					fill: $color-red;
					vertical-align: middle;
				}
				&.green {
					svg { fill: $color-green2; }
				}
				&.large {
					svg { height: 70px; }
				}
			}
			.__title {
				width: 160px;
				line-height: 1.5;
			}
			.__data {
				width: calc(100% - 220px);
				min-width: 320px;
				white-space: nowrap;
				.js-totalEnergy { line-height: 0.9; }

				.__unit {
					display: inline-block;
					vertical-align: -30%;
				}
			}
		}
		@include media(1260px) {
			&-item {
				position: relative;
				display: block;
				padding: 20px 10px 20px 90px;
				font-size: 1.7rem;
				.__icon {
					width: 90px;
					left: 0;
				}
				.__title {
					width: 100%;
				}
				.__data {
					width: 100%;
					min-width: 100%;
				}
				br[data-view="max"] { display: none; }
			}
		}
		@include media(tablet) {
			background-position: 0 100px;
			background-size: 90%;
		}
		@include media(sp) {
			background-position: 0 70px;
			h3 { font-size: 2.0rem; }
			&-item {
				br[data-view="max"] { display: block; }
			}
		}
	}
}
