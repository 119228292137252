@charset "utf-8";
// ====================================== //
// helper //
@mixin module {
/*
---
name: width- 横幅の指定
category:
  - helper
  - helper/width
---
5%刻みで指定が出来ます。(5 ～ 100%)

width-u-*: PC向け
width-lg-*: タブレット端末向け
width-md-*: スマホ端末向け
```html
<div class="width-u-5 width-lg-50 width-md-100 color-bg_light_gray4">test</div>
<div class="width-u-10 width-lg-45 width-md-95 color-bg_light_gray4">test</div>
<div class="width-u-15 width-lg-40 width-md-90 color-bg_light_gray4">test</div>
<div class="width-u-20 width-lg-35 width-md-85 color-bg_light_gray4">test</div>
<div class="width-u-25 width-lg-30 width-md-80 color-bg_light_gray4">test</div>
<div class="width-u-30 width-lg-25 width-md-75 color-bg_light_gray4">test</div>
<div class="width-u-35 width-lg-20 width-md-70 color-bg_light_gray4">test</div>
<div class="width-u-40 width-lg-15 width-md-65 color-bg_light_gray4">test</div>
<div class="width-u-45 width-lg-10 width-md-60 color-bg_light_gray4">test</div>
<div class="width-u-50 width-lg-5 width-md-55 color-bg_light_gray4">test</div>
<div class="width-u-55 width-lg-15 width-md-50 color-bg_light_gray4">test</div>
<div class="width-u-60 width-lg-25 width-md-45 color-bg_light_gray4">test</div>
<div class="width-u-65 width-lg-35 width-md-40 color-bg_light_gray4">test</div>
<div class="width-u-70 width-lg-45 width-md-35 color-bg_light_gray4">test</div>
<div class="width-u-75 width-lg-55 width-md-30 color-bg_light_gray4">test</div>
<div class="width-u-80 width-lg-65 width-md-25 color-bg_light_gray4">test</div>
<div class="width-u-85 width-lg-75 width-md-20 color-bg_light_gray4">test</div>
<div class="width-u-90 width-lg-85 width-md-15 color-bg_light_gray4">test</div>
<div class="width-u-95 width-lg-95 width-md-10 color-bg_light_gray4">test</div>
<div class="width-u-100 width-lg-100 width-md-5 color-bg_light_gray4">test</div>
```
*/
	@for $i from 1 through 20 {
		.width-u-#{$i * 5} { width: #{$i * 5 +'%'};}
	}
	@include media(tablet) {
		@for $i from 1 through 20 {
			.width-lg-#{$i * 5} { width: #{$i * 5 +'%'};}
		}
	}
	@include media(sp) {
		@for $i from 1 through 20 {
			.width-md-#{$i * 5} { width: #{$i * 5 +'%'};}
		}
	}

/*
---
name: space- スペースの指定
category:
- helper
- helper/space
---
ボックス同士の間隔を調整します。Pure Gridsとの併用を推奨します。
5px単位で指定出来ます。
```html
<div class="pure-g space-u-10">
	<div class="pure-u-1-3 space-u-10-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
	<div class="pure-u-1-3 space-u-10-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
	<div class="pure-u-1-3 space-u-10-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
</div>
<div class="mod-spacer">
	<div class="pure-g space-u-25">
		<div class="pure-u-1-4 space-u-25-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
		<div class="pure-u-1-4 space-u-25-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
		<div class="pure-u-1-4 space-u-25-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
		<div class="pure-u-1-4 space-u-25-child"><img class="width-u-100" src="//placehold.jp/200x200.png" alt=""></div>
	</div>
</div>
```
*/
	@for $i from 1 through 10 {
		.space-u {
			&-#{$i * 5} {
				margin-top: -#{$i * 5}px;
				margin-left: -#{$i * 5}px;

				&-child {
					padding-top: #{$i * 5}px;
					padding-left: #{$i * 5}px;
				}
			}

			@include media(sp) {
				&-#{$i * 5} {
					margin-top: -#{$i * 5 / $sp_num * 100}vw;
					margin-left: -#{$i * 5 / $sp_num * 100}vw;

					&-child {
						padding-top: #{$i * 5 / $sp_num * 100}vw;
						padding-left: #{$i * 5 / $sp_num * 100}vw;
					}
				}
			}
		}
	}

/*
---
name: margin, paddingの指定
category:
  - helper
  - helper/margin
---
5px刻みで0から100pxまで指定が出来ます。

m0～m100 - margin: 0px ～ 100px
mt0～mt100 - margin-top: 0px ～ 100px
mr0～mr100 - margin-right: 0px ～ 100px
mb0～mb100 - margin-bottom: 0px ～ 100px
ml0～ml100 - margin-left: 0px ～ 100px

p0～p100 - padding: 0px ～ 100px
pt0～pt100 - padding-top: 0px ～ 100px
pr0～pr100 - padding-right: 0px ～ 100px
pb0～pb100 - padding-bottom: 0px ～ 100px
pl0～pl100 - padding-left: 0px ～ 100px
```html
<div class="mt50">test</div>
<div class="mb20">test</div>
<span class="mr20">test</span><span class="mr20">test</span>
<span class="ml20">test</span><span class="ml20">test</span>
```
*/
	.m0 { margin: 0 !important;}
	.mt0 { margin-top: 0 !important;}
	.mr0 { margin-right: 0 !important;}
	.mb0 { margin-bottom: 0 !important;}
	.ml0 { margin-left: 0 !important;}
	.p0 { padding: 0 !important;}
	.pt0 { padding-top: 0 !important;}
	.pr0 { padding-right: 0 !important;}
	.pb0 { padding-bottom: 0 !important;}
	.pl0 { padding-left: 0 !important;}
	.idt { text-indent: 0 !important; }

	@for $i from 1 through 20 {
		.m#{$i * 5} { margin: #{$i * 5}px;}
		.mt#{$i * 5} { margin-top: #{$i * 5}px;}
		.mb#{$i * 5} { margin-bottom: #{$i * 5}px;}
		.mr#{$i * 5} { margin-right: #{$i * 5}px;}
		.ml#{$i * 5} { margin-left: #{$i * 5}px;}
		.p#{$i * 5} { padding: #{$i * 5}px;}
		.pt#{$i * 5} { padding-top: #{$i * 5}px;}
		.pr#{$i * 5} { padding-right: #{$i * 5}px;}
		.pb#{$i * 5} { padding-bottom: #{$i * 5}px;}
		.pl#{$i * 5} { padding-left: #{$i * 5}px;}
		.idt#{$i * 5} { text-indent: -#{$i * 5}px;}

		@include media(sp) {
			.m#{$i * 5} { margin: #{$i * 5 / $sp_num * 100}vw;}
			.mt#{$i * 5} { margin-top: #{$i * 5 / $sp_num * 100}vw;}
			.mb#{$i * 5} { margin-bottom: #{$i * 5 / $sp_num * 100}vw;}
			.mr#{$i * 5} { margin-right: #{$i * 5 / $sp_num * 100}vw;}
			.ml#{$i * 5} { margin-left: #{$i * 5 / $sp_num * 100}vw;}
			.p#{$i * 5} { padding: #{$i * 5 / $sp_num * 100}vw;}
			.pt#{$i * 5} { padding-top: #{$i * 5 / $sp_num * 100}vw;}
			.pr#{$i * 5} { padding-right: #{$i * 5 / $sp_num * 100}vw;}
			.pb#{$i * 5} { padding-bottom: #{$i * 5 / $sp_num * 100}vw;}
			.pl#{$i * 5} { padding-left: #{$i * 5 / $sp_num * 100}vw;}
			.idt#{$i * 5} { text-indent: -#{$i * 5 / $sp_num * 100}vw;}
		}
	}

/*
---
name: color_ 文字色の指定
tag:
  - color
category:
  - helper
  - helper/color
---
```html
<div class="fw_bold">
<div class="width-u-100 color_txt1">#000</div>
<div class="width-u-100 color_txt2">#666</div>
<div class="width-u-100 color_gray">#ddd</div>
<div class="width-u-100 color_white">#fff</div>
<div class="width-u-100 color_red">#b30000</div>
<div class="width-u-100 color_blue">#3772a6</div>

</div>
```
*/
	.color {
		&_txt1 { color: $color-txt1;}
		&_txt2 { color: $color-txt2;}
		&_gray { color: $color-gray;}
		&_white { color: #fff;}
		&_red { color: $color-red;}
		&_green { color: $color-green;}
		&_lightgreen { color: $color-green2; }

/*
---
name: color-bg_ 背景色の指定
tag:
  - color
category:
  - helper
  - helper/color
---
```html
<div class="width-u-100 color-bg_black">#000</div>
<div class="width-u-100 color-bg_white">#fff</div>
<div class="width-u-100 color-bg_red">#b30000</div>
<div class="width-u-100 color-bg_blue">#3772a6</div>
<div class="width-u-100 color-bg_green">#116715</div>
<div class="width-u-100 color-bg_orange">#ff7a21</div>
<div class="width-u-100 color-bg_lightRed">#f9eeee</div>
```
*/
		&-bg {
			&_black { background-color: #000;}
			&_white { background-color: #fff;}
			&_red { background-color: $color-red;}
			&_blue { background-color: $color-blue;}
			&_green { background-color: $color-green;}
			&_orange { background-color: $color-orange;}
			&_lightRed { background-color: $color-light-red; }
		}

/*
---
name: color-border_ ボーダー色の指定
tag:
  - color
  - border
category:
  - helper
  - helper/color
---
```html
<div class="width-u-100 mod-border01 color-border_border1">#333</div>
<div class="width-u-100 mod-border01 color-border_border2">#ccc</div>
<div class="width-u-100 mod-border01 color-border_black">#000</div>
<div class="width-u-100 mod-border01 color-border_white">#fff</div>
<div class="width-u-100 mod-border01 color-border_red">#b30000</div>
<div class="width-u-100 mod-border01 color-border_blue">#3772a6</div>
<div class="width-u-100 mod-border01 color-border_green">#116715</div>
<div class="width-u-100 mod-border01 color-border_orange">#ff7a21</div>
```
*/
		&-border {
			&_border1 { border-color: $color-border1;}
			&_border2 { border-color: $color-border2;}
			&_black { border-color: #000;}
			&_white { border-color: #fff;}
			&_red { border-color: $color-red;}
			&_blue { border-color: $color-blue;}
			&_green { border-color: $color-green;}
			&_orange { border-color: $color-orange;}
            &_gray { border-color: $color-gray;}
		}
	}

/*
---
name: border-width_ ボーダー幅の指定
tag:
  - border
category:
  - helper
  - helper/border
---
```html
<div class="border-width_1 mod-border01">1px</div>
<div class="border-width_2 mod-border01">2px</div>
<div class="border-width_3 mod-border01">3px</div>
<div class="border-width_4 mod-border01">4px</div>
<div class="border-width_5 mod-border01">5px</div>
```
*/
	.border {
		&-width {
			&_1 { border-width: 1px;}
			&_2 { border-width: 2px;}
			&_3 { border-width: 3px;}
			&_4 { border-width: 4px;}
			&_5 { border-width: 5px;}
		}
	}

/*
---
name: bo_none ボーダー削除の指定
tag:
  - border
category:
  - helper
  - helper/border
---
```html
<div class="mod-border01">border</div>
<div class="mod-border01 bo_none">border: none;</div>
```
*/
	.bo_none { border: none;}

/*
---
name: pos_ positionの指定
category:
  - helper
  - helper/other
---
```html
<section class="pos_relative">
	<div class="pos_relative">position: relative;</div>
	<div class="pos_absolute" style="top:0; right:0;">position: absolute;</div>
	<div class="pos_static">position: static;</div>
</section>
```
*/
	.pos_relative { position: relative;}
	.pos_absolute { position: absolute;}
	.pos_static { position: static;}

/*
---
name: fs_ 文字サイズの指定(tablet,SP別途指定可)
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="fs_10">10px</div>
<div class="fs_12">12px</div>
<div class="fs_14">14px</div>
<div class="fs_16">16px</div>
<div class="fs_20">20px</div>
<div class="fs_22">22px</div>
<div class="fs_30">30px</div>
<div class="fs_40">40px</div>
```
*/
	.fs {
		@for $i from 10 through 40 {
			&_#{$i} { font-size: #{$i / 10}rem;}
		}
		@include media(tablet) {
			&_lg {
				@for $i from 10 through 40 {
					&_#{$i} { font-size: #{$i / 10}rem;}
				}
			}
		}
		@include media(sp) {
			&_md {
				@for $i from 10 through 40 {
					&_#{$i} { font-size: #{$i / 10}rem;}
				}
			}
		}
	}

/*
---
name: fw_ 文字の太さを指定
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="fw_normal">normal</div>
<div class="fw_bold">bold</div>
```
*/
	.fw_normal { font-weight: normal;}
	.fw_bold { font-weight: bold;}

/*
---
name: ff_ フォント指定
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="ff_gothic">ゴシック体</div>
<div class="ff_mincho">明朝体</div>
<div class="ff_alphabet">alphabet</div>
```
*/
	.ff_gothic { font-family: $font_gothic; }
	.ff_mincho { font-family: $font_mincho; }
	.ff_alphabet { font-family: $font_alphabet; }

/*
---
name: lh_ 行間の指定
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="lh_10">line-height: 1</div>
<div class="lh_11">line-height: 1.1</div>
<div class="lh_12">line-height: 1.2</div>
<div class="lh_13">line-height: 1.3</div>
<div class="lh_14">line-height: 1.4</div>
<div class="lh_15">line-height: 1.5</div>
<div class="lh_16">line-height: 1.6</div>
<div class="lh_17">line-height: 1.7</div>
<div class="lh_18">line-height: 1.8</div>
<div class="lh_19">line-height: 1.9</div>
<div class="lh_20">line-height: 2</div>
```
*/
	.lh_10 { line-height: 1;}
	.lh_11 { line-height: 1.1;}
	.lh_12 { line-height: 1.2;}
	.lh_13 { line-height: 1.3;}
	.lh_14 { line-height: 1.4;}
	.lh_15 { line-height: 1.5;}
	.lh_16 { line-height: 1.6;}
	.lh_17 { line-height: 1.7;}
	.lh_18 { line-height: 1.8;}
	.lh_19 { line-height: 1.9;}
	.lh_20 { line-height: 2;}

/*
---
name: ls_ 文字間の指定
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="ls_m41">letter-spacing: -0.41em</div>
<div class="ls_m31">letter-spacing: -0.31em</div>
<div class="ls_m2">letter-spacing: -2px</div>
<div class="ls_m1">letter-spacing: -1px</div>
<div class="ls_0">letter-spacing: normal</div>
<div class="ls_p1">letter-spacing: 1px</div>
<div class="ls_p2">letter-spacing: 2px</div>
<div class="ls_p3">letter-spacing: 3px</div>
<div class="ls_p4">letter-spacing: 4px</div>
```
*/
	.ls_m41 { letter-spacing: -0.41em;}
	.ls_m31 { letter-spacing: -0.31em;}
	.ls_m2 { letter-spacing: -2px;}
	.ls_m1 { letter-spacing: -1px;}
	.ls_0 { letter-spacing: normal;}
	.ls_p1 { letter-spacing: 1px;}
	.ls_p2 { letter-spacing: 2px;}
	.ls_p3 { letter-spacing: 3px;}
	.ls_p4 { letter-spacing: 4px;}

/*
---
name: td_ text-decorationの指定
tag:
  - block
category:
  - helper
  - helper/block
---
```html
<div class="td_under">text-decoration: underline;</div>
```
*/
	.td_under { text-decoration: underline;}

/*
---
name: ta_ 行揃えの位置指定
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="ta_left">左揃え</div>
<div class="ta_center">中央揃え</div>
<div class="ta_right">右揃え</div>
```
*/
	.ta_left { text-align: left;}
	.ta_center { text-align: center;}
	.ta_right { text-align: right;}

/*
---
name: ta_lg_ 行揃えの位置指定（tablet用）
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="ta_lg_left">左揃え</div>
<div class="ta_lg_center">中央揃え</div>
<div class="ta_lg_right">右揃え</div>
```
*/
	@include media(tablet) {
		.ta_lg_left { text-align: left;}
		.ta_lg_center { text-align: center;}
		.ta_lg_right { text-align: right;}
	}

/*
---
name: ta_md_ 行揃えの位置指定（SP用）
tag:
  - text
category:
  - helper
  - helper/text
---
```html
<div class="ta_md_left">左揃え</div>
<div class="ta_md_center">中央揃え</div>
<div class="ta_md_right">右揃え</div>
```
*/
	@include media(sp) {
		.ta_md_left { text-align: left;}
		.ta_md_center { text-align: center;}
		.ta_md_right { text-align: right;}
	}

/*
---
name: va_ 縦方向の揃え位置指定
tag:
  - text
  - table
category:
  - helper
  - helper/text
---
```html
<table class="width-u-100 mod-table_fixed">
<tr>
	<td height="100" class="va_top">上揃え</td>
	<td height="100" class="va_middle">中央揃え</td>
	<td height="100" class="va_bottom">下揃え</td>
</tr>
</table>
```
*/
	.va_top { vertical-align: top;}
	.va_middle { vertical-align: middle;}
	.va_bottom { vertical-align: bottom;}

/*
---
name: fl_ floatの指定
tag:
  - block
category:
  - helper
  - helper/block
---
```html
<div class="clearfix">
	<div class="fl_left">float: left;</div>
	<div class="fl_right">float: right;</div>
	<div class="fl_right fl_none">float: none;</div>
</div>
```
*/
	.fl_left { float: left;}
	.fl_right { float: right;}
	.fl_none { float: none;}

/*
---
name: cl_both clearの指定
tag:
  - block
category:
  - helper
  - helper/block
---
```html
<div class="cl_both">clear: both;</div>
```
*/
	.cl_both { clear: both;}

/*
---
name: di_ blockの指定
tag:
  - block
category:
  - helper
  - helper/block
---
```html
<div class="di_block">display: block;</div>
<div class="di_iblock">display: inline-block;</div>
<div class="di_inline">display: inline;</div>
<div class="di_none">display: none;</div>
```
*/
	.di_block { display: block;}
	.di_iblock { display: inline-block;}
	.di_inline { display: inline;}
	.di_none { display: none;}

/*
---
name: opacity 透過度の指定
tag:
- anchor
category:
- helper
- helper/anchor
---
親要素に指定し、その子要素に存在するanchorタグをマウスオーバーした際の透過度を調整します。
または透過させたい要素に直接指定することも可能です。
```html
<div>
<a href="#">透過調整無し</a>
</div>
<div class="opacity">
<a href="#">透過調整有り</a>
</div>
```
*/
	.opacity a, a.opacity {
		transition: all 0.3s ease;
		opacity: 1;

		&:hover {
			opacity: 0.55;
		}

		@include media(sp) {
			&:hover {
				opacity: 1;
			}
		}
	}

/*
---
name: ul- 汎用リスト
tag:
- block
category:
- helper
- helper/list
---
親要素に指定し、その子要素に存在するliタグのマーカーを指定します。
```html
<ul class="ul-disc">
	<li>黒丸</li>
</ul>
<ul class="ul-circle">
	<li>白丸</li>
</ul>
<ul class="ul-square">
	<li>黒四角</li>
</ul>
<ul class="ul-decimal">
	<li>算用数字</li>
</ul>
<ul class="ul-latin">
	<li>小文字アルファベット</li>
</ul>
<ul class="ul-upperLatin">
	<li>大文字アルファベット</li>
</ul>
```
*/
	.ul {
		&-disc,&-circle,&-square,&-decimal,&-latin,&-upperLatin {
			margin-left: 20px;
		}
		&-disc > li { list-style: disc; margin-bottom: 10px;}
		&-circle > li { list-style: circle; margin-bottom: 10px;}
		&-square > li { list-style: square; margin-bottom: 10px;}
		&-decimal > li { list-style: decimal; margin-bottom: 10px;}
		&-latin > li { list-style: lower-latin; margin-bottom: 10px;}
		&-upperLatin > li { list-style: upper-latin; margin-bottom: 10px;}
		&-paren {
			counter-reset: count_paren;
			> li {
				position: relative;
				padding-left: 50px;
				margin-bottom: 5px;
				&:before {
					counter-increment: count_paren;
				  content: "（" counter(count_paren) "）";
					position: absolute;
					left: 0;
				}
			}
		}
		&-star {
			> li {
				position: relative;
				padding-left: 20px;
				&:before {
					content: '※';
					position: absolute;
					left: 0;
				}
			}
		}
	}

/*
---
name: ti_paren 全角カッコのインデント
tag:
- block
category:
- helper
- helper/list
---
```
*/
	.ti_paren {
		margin-left: -0.5em;
	}

/*
---
name: print_none 印刷禁止
tag:
- block
category:
- helper
- helper/list
---
```html
<div class="print_none">このブロックは印刷されない</div>
```
*/
	@media print {
		.print_none {
			display: none;
		}
	}
}

// ====================================== //
