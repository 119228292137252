@charset "utf-8";
// ====================================== //
// recruit //
.recruit {
	&-menu {
		display: grid;
		grid-template-rows: 50% 50%;
		grid-template-columns: 50% 1fr;
		li {
			&:nth-child(1) {
				grid-row: 1 / 3;
				grid-column: 1 / 2;
				-ms-grid-column: 1;
				-ms-grid-row: 1;
				-ms-grid-row-span: 2;
			}
			&:nth-child(2) {
				grid-row: 1 / 2;
				grid-column: 2 / 3;
				-ms-grid-row: 1;
				-ms-grid-column: 2;
				margin-left: vw(40);
				padding-bottom: 10px;
			}
			&:nth-child(3) {
				grid-row: 2 / 3;
				grid-column: 2 / 3;
				-ms-grid-row: 2;
				-ms-grid-column: 2;
				margin-left: vw(40);
				padding-top: 10px;
			}
			a, img { height: 100%; }
		}
		@include media(sp) {
			display: block;
			li {
				&:nth-child(2), &:nth-child(3) {
					margin: 20px 0 0;
					padding: 0;
				}
			}
		}
	}
	&-policy {
		&-mainVis {
			position: relative;
			.__txt {
				position: absolute;
				left: 20px;
				bottom: 20px;
				color: #fff;
				text-align: left;
				line-height: 1.5;
			}
			@include media(sp) {
				.__txt {
					left: 10px;
					bottom: 10px;
				}
			}
		}
	}
	&-env {
		&-system {
			padding: 30px 40px;
			background-color: $color-light-red;
			@include media(sp) { padding: 20px; }
		}
		&-women {
			padding: 30px 40px;
			background-color: #fff;
			.__body {
				display: flex;
				align-items: center;
				figure {
					width: 40%;
					text-align: center;
				}
				div {
					width: 50%;
					color: $color-red;
					font-family: $font_alphabet;
					font-size: 3.4rem;
					font-weight: 700;
					text-align: right;
					line-height: 1.2;
					.__num {
						font-size: 6.8rem;
						line-height: 1;
					}
				}
			}
			@include media(sp) {
				padding: 30px;
				.__body {
					figure {
						img { width: 70px; }
					}
					div {
						font-size: 2.5rem;
						.__num { font-size: 5.8rem; }
					}
				}
			}
		}
	}
	&-support {
		&-tbl {
			tbody {
				th, td {
					text-align: center;
				}
				td {
					border-right: 1px solid $color-gray;
					&:last-child { border-right: 0; }
				}
				@include media(sp) {
					td {
						border-top: 1px solid $color-gray;
						border-right: 0;
					}
				}
			}
		}
	}
}