@charset "utf-8";
.mod {
/*
---
name: mod-a アンカータグのブロック要素化
tag:
  - block
category:
  - module
  - module/anchor
---
anchorタグをblock要素にし、横幅縦幅100%にします。
```html
<a href="#" class="mod-a color-bg_gray">テスト</a>
```
*/
	&-a {
		display: block;
		width: 100%;
		height: 100%;
	}
/*
---
name: mod-textLink 汎用テキストリンク
tag:
  - block
category:
  - module
  - module/anchor
---
汎用テキストリンク
```html
<a href="#" class="mod-textLink01">テスト</a>
```
*/
	&-textLink {
		&01 {
			text-decoration: underline;
			.icon {
				width: 12px;
				height: 12px;
				margin-left: 10px;
			}
			.blank {
				color: #b30000;
			}
		}
	}
/*
---
name: mod-auto ブロック要素のセンタリング
tag:
- block
category:
- module
- module/block
---
ブロック要素のセンタリングです。
```html
<div class="color-bg_gray mb10 mod-auto">1270px（default）</div>
<div class="color-bg_gray mb10 mod-auto mod-auto_900">900px</div>
<div class="color-bg_gray mb10 mod-auto-full">PCはfull（padding:vw30）、mobileはフルリキッド)</div>
<div class="color-bg_gray mb10 mod-auto-pc">PCは1270px、mobileはフルリキッド</div>
<div class="color-bg_gray mb10 mod-auto-center width-u-50">中央寄せ（サイズ指定別途）</div>
```
*/
	&-auto {
		margin-right: auto;
		margin-left: auto;
		padding-right: 50px;
		padding-left: 50px;
		max-width: 1300px;
		width: 100%;

		&_900 { max-width: 1000px; }

		&-full {
			width: 100%;
			padding-right: vw(30);
			padding-left: vw(30);
		}
		&-pc {
			margin-right: auto;
			margin-left: auto;
			padding-right: 50px;
			padding-left: 50px;
			max-width: 1370px;
			width: 100%;
		}
		&-center {
			margin-left: auto;
			margin-right: auto;
		}

		@include media(tablet) {
			max-width: 90%;
			padding-right: 0;
			padding-left: 0;

			&-full {
				width: 100%;
				padding-right: 5%;
				padding-left: 5%;
			}
			&-pc, &_900 {
				max-width: 90%;
				padding-right: 0;
				padding-left: 0;
			}
		}
		@include media(sp) {
			&-pc {
				max-width: 100%;
				padding-right: 0;
				padding-left: 0;
			}
		}
	}

/*
---
name: mod-border 汎用ボーダー
tag:
- border
category:
- module
- module/border
---
汎用のボーダーです。
```html
<div class="mod-border mb20">ボーダー 1px #333</div>
<div class="mod-borderTop mb20">上ボーダー 1px #333</div>
<div class="mod-borderBottom mb20">下ボーダー 1px #333</div>
```
*/
	&-border {
		border: 1px solid $color-border1;
		&02 { border: 1px solid $color-border2; }

		&Top { border-top: 1px solid $color-border1; }
		&Top02 { border-top: 1px solid $color-border2; }

		&Bottom { border-bottom: 1px solid $color-border1; }
	}

/*
---
name: mod-spacer 要素間用スペース
tag:
- block
category:
- module
- module/block
---
要素間用の共通スペーサー。
```html
<p class="color-bg_gray mod-spacer">要素間用スペース</p>
<p class="color-bg_gray mod-spacer-section">セクション間用スペース</p>
```
*/
	&-spacer {
		&_40 { margin-top: 40px; }
		&_60 { margin-top: 60px; }
		&_100 { margin-top: 100px; }
		&_120 { margin-top: 120px; }

		@include media(tablet) {
			&_40 { margin-top: 40px; }
			&_60 { margin-top: 50px; }
			&_100 { margin-top: 60px; }
			&_120 { margin-top: 80px; }
		}
		@include media(sp) {
			&_40 { margin-top: vw(40,sp); }
			&_60 { margin-top: vw(60,sp);}
			&_100 { margin-top: vw(100,sp); }
			&_120 { margin-top: vw(120,sp); }
		}
	}

/*
---
name: mod-head 見出し
tag:
- block
category:
- module
- module/block
---
各見出しです。
```html
<div class="mod-head01">
	<span class="mod-head01-inner">見出し1</span>
</div>
<h2 class="mod-head02 mt20">見出し2</h2>
---
*/
	&-head {
		&01 { // 各ページのH1要素に使う
			position: relative;
			line-height: 1.5;
			.__txt {
				position: absolute;
				top: 45%;
				width: 100%;
				color: #fff;
				font-size: 4.2rem;
				text-align: center;
				line-height: 1.2;
				text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6), 0px 0px 4px rgba(0, 0, 0, 0.6), 0px 0px 4px rgba(0, 0, 0, 0.6);

				span { display: block; }
				.__small {
					margin-bottom: 10px;
					font-size: 1.6rem;
				}
				.__en {
					font-family: $font_alphabet;
					font-size: 4.8rem;
					letter-spacing: 2px;
					._icon {
						position: relative;
						display: inline-block;
						padding: 0 70px;
						svg {
							position: absolute;
							right: 0;
							top: 50%;
							width: 20px;
							height: 20px;
							fill: #fff;
							transform: translateY(-50%);
						}
					}
				}
			}
			&._sports {
				.__txt {
					top: 50%;
					transform: translateY(-50%);
				}
			}
			@include media(tablet) {
				.__txt {
					top: 40%;
					font-size: 3.6rem;
					.__en { font-size: 4.0rem; }
				}
			}
			@include media(sp) {
				.__txt {
					top: 50%;
					transform: translateY(-50%);
					font-size: 2.8rem;
					.__small { font-size: 1.4rem; }
					.__en {
						font-size: 3.0rem;
						._icon {
							padding: 0 vw(90px,$sp);
							svg {
								width: vw(30px,$sp);
								height: vw(30px,$sp);
							}
						}
					}
				}
			}
		}
		&02 {
			margin-bottom: 40px;
			font-size: 3.0rem;
			font-weight: 400;
			text-align: center;
			@include media(sp) {
				font-size: 2.4rem;
			}
		}
		&03 {
			margin-bottom: 40px;
			padding-bottom: 10px;
			border-bottom: 1px solid $color-red;
			font-size: 2.0rem;
			font-weight: normal;
			&.green { border-bottom-color: $color-green2; }

			@include media(sp) {
				margin-bottom: vw(40px,$sp);
				font-size: 1.6rem;
			}
		}
		&04 {
			position: relative;
			padding-left: 17px;
			font-size: 1.6rem;
			&:before {
				position: absolute;
				display: block;
				content: '';
				top: 50%;
				left: 0;
				width: 2px;
				height: 100%;
				background-color: $color-red;
				transform: translateY(-50%);
			}
			@include media(sp) {
				font-size: 1.4rem;
			}
		}
		&05 {
			position: relative;
			margin-bottom: 20px;
			padding-left: 50px;
			font-size: 1.6rem;
			.__num {
				position: absolute;
				left: 0; top: 0;
				display: inline-block;
				width: 30px; height: 34px;
				background-color: $color-red;
				color: #fff;
				font-size: 2.0rem;
				font-weight: 700;
				text-align: center;
				line-height: 1.6;
			}
		}
	}

/*
---
name: mod-topicPath 汎用パンくず
tag:
- block
- list
category:
- module
- module/block
- module/list
---
```html
<nav class="mod-topicPath">
	<ul class="mod-topicPath-ul clearfix" itemscope itemtype="http://schema.org/BreadcrumbList">
		<li class="mod-topicPath-li" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
			<a class="mod-topicPath-a" itemprop="item" href="/"><span itemprop="name">ホーム</span></a>
			<svg><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg>
			<meta itemprop="position" content="1" />
		</li>
		<li class="mod-topicPath-li" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
			<a class="mod-topicPath-a" itemprop="item" href="/about/">
				<span class="mod-topicPath-span" itemprop="name">ABOUT</span>
			</a>
			<svg><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg>
			<meta itemprop="position" content="2" />
		</li>
		<li class="mod-topicPath-li" itemprop="itemListElement" itemscope itemtype="http://schema.org/ListItem">
			<a class="mod-topicPath-a" itemprop="item" href="/about/">
				<span class="mod-topicPath-span" itemprop="name">会社概要</span>
			</a>
			<meta itemprop="position" content="3" />
		</li>
	</ul>
</nav>
```
*/
	&-topicPath {
		display: block;
		width: 100%;
		height: auto;
		padding-top: 10px;
		padding-bottom: 10px;
		line-height: 1.2;
		letter-spacing: -.4em;

		&-li {
			display: inline-block;
			vertical-align: middle;
			font-size: 1.6rem;
			letter-spacing: normal;

			svg {
				width: 10px;
				height: 10px;
				margin-left: 10px;
				margin-right: 10px;
				fill: $color-txt2;
			}

			&:last-child {
				a {
					pointer-events: none;
					color: $color-txt1;
					font-weight: bold;
					text-decoration: none;
					&:hover { opacity: 1; }
				}
				svg { display: none; }
			}
		}
		&-a, &-a:visited, &-a:link {
			display: inline-block;
			color: $color-txt1;
			transition: color .3s;
			&:hover {
				color: $color-red;
				text-decoration: underline;
			}
		}

		@include media(tablet) {
			overflow-x: scroll;

			&-ul {
				white-space: nowrap;
			}
			&-li {
				&:last-child {
					a {
						padding-right: 10px;
					}
				}
			}
		}
	}

/*
---
name: mod-button 汎用ボタン
tag:
- anchor
category:
- module
- module/anchor
---
aタグのみで完結
```html
<a href="/" class="mod-button _300 _large">
	<span>ボタン＋右アイコン<br>デフォルトopacity</span>
	<svg class="icon mod-iconRight"><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg>
</a>
<a href="/" class="mod-button _300 __white mt20">
	<span>ボタン白＋左アイコン</span>
	<svg class="icon mod-iconLeft"><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg>
</a>
<div class="ta_left">
	<a href="/" class="mod-button _300 _iblock __white mt20">
		<span>左右寄せの場合は_iblockを使用</span>
		<svg class="icon mod-iconLeft"><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg>
	</a>
	<a href="/" class="mod-button _300 _iblock __white mt20">
		<span>ボタン白＋左アイコン</span>
		<svg class="icon mod-iconLeft"><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg>
	</a>
</div>
```
*/
	&-button {
		position: relative;
		display: block;
		width: 150px;
		height: 45px;
		margin-left: auto;
		margin-right: auto;
		line-height: 1.5;
		border: 1px solid $color-red;
		color: $color-red;
		cursor: pointer;
		transition: .3s;
		svg {
			fill: $color-red;
			transition: .3s;
		}
		&:hover {
			background-color: $color-red;
			color: #fff;
			svg { fill: #fff; }
		}
		@include media(tablet) {
			&:hover {
				background-color: transparent;
				color: $color-red;
				svg { fill: $color-red; }
			}
		}

		> span {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 100%;
			padding: 0 15px;
			text-align: center;
			&.ta_left { text-align: left; }
			svg {
				width: 20px;
				height: 20px;
				vertical-align: middle;
			}
			@include media(tablet) {
				&.ta_lg_left { text-align: left; }
			}
			@include media(sp) {
				&.ta_md_left { text-align: left; }
			}
		}

		&._200 { width: 200px; }
		&._250 { width: 250px; }
		&._300 { width: 300px; }
		&._350 { width: 350px; }
		&._large { height: 60px; }
		&._large_max { height: 100px; }

		&._iblock {
			display: inline-block;
			& + .mod-button._iblock {
				margin-left: 10px;
			}
		}
		@include media(sp) {
			&._350 { width: 100%; }
		}

		&.__white {
			position: relative;
			background-color: rgba(255,255,255,0);
			border-color: #fff;
			color: #fff;
			transition: .3s;
			svg { fill: #fff; }

			&:hover {
				opacity: 1;
				background-color: rgba(255,255,255,1);
				color: $color-red;
				svg { fill: $color-red; }
				@include media(tablet) {
					background-color: rgba(255,255,255,0);
					color: $color-red;
					svg { fill: $color-red; }
				}
			}
		}

		&.__green {
			position: relative;
			background-color: rgba(255,255,255,0);
			border-color: $color-green2;
			color: $color-green2;
			transition: .3s;
			svg { fill: $color-green2; }

			&:hover {
				opacity: 1;
				background-color: $color-green2;
				color: #fff;
				svg { fill: #fff; }
				@include media(tablet) {
					background-color: rgba(255,255,255,0);
					color: $color-green2;
					svg { fill: $color-green2; }
				}
			}
		}

		&.__gray {
			position: relative;
			border-color: $color-gray;
			color: #000;
			transition: .3s;
			&:hover {
				opacity: 1;
				background-color: $color-gray;
				color: #fff;
				svg { fill: #fff; }
				@include media(tablet) {
					background-color: rgba(255,255,255,0);
					color: $color-txt1;
				}
			}
			&.green {
				svg { fill: $color-green2; }
				@include media(tablet) {
					&:hover {
						svg { fill: $color-green2; }
					}
				}
			}
		}

		&.is-disable {
			cursor: default;
			pointer-events: none;
		}
	}

/*
---
name: mod-icon 汎用ボタン内アイコン
tag:
- block
category:
- module
- module/block
---
mod-button内につけるアイコンスタイル
```html
<div class="mod-button mb10">
	<a href="" class=" mod-button-a"><span>アイコン右：通常リンク用矢印</span><i class="icon icon-arrow mod-iconRight"></i></a>
</div>
<div class="mod-button mb10">
	<a href="" class=" mod-button-a"><span>アイコン右：別窓リンク用</span><i class="icon icon-blank mod-iconRight"></i></a>
</div>
```
*/
	&-icon {
		&ArrowRight {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			width: 14px;
			height: 14px;
		}
		&ArrowLeft {
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
			width: 14px;
			height: 14px;
		}
		&Right {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
			height: 20px;
		}
		&Left {
			position: absolute;
			left: 20px;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
			height: 20px;
		}
		&Swipe {
			width: 50px;
			height: 20px;
			vertical-align: middle;
			fill: $color-red;
			&.green { fill: $color-green; }
		}
		@include media(sp) {
			&Swipe { width: vw(100px,$sp); }
		}
	}

/*
---
name: mod-link テキストリンク
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-link {
		&01 {
			color: $color-red;
			text-decoration: underline;
			svg {
				width: 16px;
				height: 16px;
				margin-left: 5px;
				vertical-align: middle;
				fill: $color-red;
				transform: translateY(-2px);
			}
		}
		&02 {
			text-decoration: underline;
			transition: .3s;
			svg {
				width: 16px;
				height: 16px;
				margin-left: 5px;
				vertical-align: middle;
				fill: $color-red;
				transform: translateY(-2px);
			}
			&:hover {
				color: $color-red;
			}
			&.green {
				svg { fill: $color-green2; }
				&:hover { color: $color-green2; }
			}
		}
		&03 {
			color: $color-green2;
			svg {
				width: 16px;
				height: 16px;
				margin-left: 5px;
				vertical-align: middle;
				fill: $color-green2;
				transform: translateY(-2px);
			}
		}
		&04 {
			transition: .3s;
			svg {
				width: 16px;
				height: 16px;
				margin-left: 5px;
				vertical-align: middle;
				fill: $color-red;
				transform: translateY(-2px);
			}
			&:hover {
				color: $color-red;
			}
		}

	}

/*
---
name: mod-boxLink ボックスリンク
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-boxLink {
		&01 {
			position: relative;
			display: block;
			line-height: 1.5;
			box-shadow: 0 0 10px 5px rgba(0,0,0,.15);
			> span {
				position: absolute;
				top: 50%; left: 0;
				transform: translateY(-50%);
				width: 100%;
				padding: 0 10px;
				text-align: center;
				color: #fff;
				text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.6), 0px 0px 4px rgba(0, 0, 0, 0.6), 0px 0px 4px rgba(0, 0, 0, 0.6);
				letter-spacing: 0.1em;
			}
			svg {
				width: 14px; height: 14px;
				fill: #fff;
			}
			@include media(sp) {
				box-shadow: 0 0 5px 5px rgba(0,0,0,.15);
			}
		}
		&02 {
			position: relative;
			display: block;
			img { box-shadow: 0 0 10px 5px rgba(0,0,0,.1); }
			figcaption {
				padding-top: 10px;
				line-height: 1.5;
			}
			&._movie {
				figcaption {
					position: relative;
					padding-top: 15px;
					padding-left: 40px;
				}
				svg {
					position: absolute;
					top: 13px; left: 0;
					width: 30px; height: 30px;
					fill: $color-red;
				}
			}
			@include media(tablet) {
				figcaption { font-size: 1.4rem; }
			}
			@include media(sp) {
				&._movie {
					figcaption { padding-left: 25px; }
					svg {
						width: 20px; height: 20px;
						top: 15px;
					}
				}
			}
		}
	}
/*
---
name: mod-box 汎用ブロック
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-box {
		&01 {
			background: #f9eeee;
			display: flex;
			flex-flow: row wrap;
			align-content: flex-start;
			align-items: center;
			padding: 15px 20px;
			> p {
				width: calc(100% - 290px);
				&.__logo {
					width: 290px;
					background: #fff;
					display: flex;
					flex-flow: row wrap;
					align-content: flex-start;
					align-items: center;
					padding: 15px;
					> span {
						display: block;
						width: calc(100% - 40px);
						text-align: center;
					}
					> img {
						width: 40px;
					}
				}
			}
			@include media(sp) {
				padding: 15px;
				> p {
					width: calc(100% - 160px);
					&.__logo {
						width: 160px;
						padding: 15px;
						> span {
							width: calc(100% - 20px);
						}
						> img {
							width: 20px;
						}
					}
				}
			}
		}
	}
/*
---
name: mod-sns SNSアイコン
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-sns {
		text-align: center;
		li {
			display: inline-block;
			margin-right: 25px;
			&:last-child { margin-right: 0; }
			a { display: block; }
			img { height: 42px; }
		}
	}

/*
---
name: mod-newsList ニュース一覧
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-newsList {
		li {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 30px;
			line-height: 1.5;
			&:last-child { margin-bottom: 0; }
		}
		time {
			width: 120px;
		}
		.__label {
			width: 130px;
			letter-spacing: -.4em;
			> span, > a {
				display: inline-block;
				letter-spacing: normal;
				& + span { margin-left: 5px; }
				& + a { margin-left: 5px; }
			}
			> br + span,
			> br + a { margin-left: 5px; }

			.mod-newsLabel {
				width: 100px;
				padding: 2px 5px;
				font-size: 1.3rem;
			}
			._player { width: 120px; }
		}
		.__title {
			width: calc(100% - 250px);
			a {
				transition: .3s;
				&:hover {
					color: $color-red;
				}
			}
			svg {
				width: 16px; height: 16px;
				margin-left: 8px;
				fill: $color-red;
				transform: translateY(2px);
				transition: .3s;
				&.green { fill: $color-green; }
			}
		}
		&._noLabels { .__title { width: calc(100% - 120px); } }
		&._noDate   { .__title { width: 100%; } }
		&._sports   {
			.__label { width: calc(100% - 120px); }
			.__title {
				width: 100%;
				margin-top: 10px;
			}
		}
		&._result {
			time { width: 300px; }
			.__title { width: calc(100% - 430px); }
		}

		@include media(tablet) {
			li { flex-wrap: wrap; }
			time { width: 100px; }
			.__label { width: 100px; }
			.__title {
				width: 100%;
				margin-top: 10px;
				a:hover { color: $color-txt1; }
			}
			&._sports {
				.__label { width: 100%; }
			}
			&._noLabels .__title, &._noDate .__title { width: 100%; }
			&._noDate .__title { margin-top: 0; }
			&._result {
				.__title { width: 100%; }
			}
		}
		@include media(sp) {
			.__label {
				> br + span,
				> br + a { margin-left: 0; }
			}
			&._sports {
				.__label .mod-newsLabel { font-size: 1.0rem; }
				.__label ._player { width: 90px; }
			}
			&._result {
				time { width: 220px; }
				.__label {
					width: calc(100% - 220px);
					.mod-newsLabel { width: 80px; }
				}
				.__title { width: 100%; }
			}
		}
	}

/*
---
name: mod-newsLabel ニュース用ラベルカラー設定
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-newsLabel {
		color: #fff;
		border: 1px solid #fff;
		text-align: center;

		// コーポレート＞ニュース
		&.ir    { background-color: $color-red; border-color: $color-red; }
		&.media { background-color: $color-blue; border-color: $color-blue; }
		&.info  { background-color: $color-green; border-color: $color-green; }

		// コーポレート＞メディア掲載
		&.media_biz_ichigo { background-color: $color-red; border-color: $color-red; }
		&.media_biz_office { background-color: $color-blue; border-color: $color-blue; }
		&.media_biz_hotel  { background-color: $color-orange; border-color: $color-orange; }
		&.media_biz_green  { background-color: $color-light-green; border-color: $color-light-green; }
		&.media_biz_plant  { background-color: $color-green; border-color: $color-green; }
		&._category {
			color: $color-txt1;
			border-color: $color-border1;
		}
	}

/*
---
name: mod-newsDetail ニュース詳細
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-newsDetail {
		&-head {
			line-height: 1.5;
			.mod-newsLabel {
				display: inline-block;
				width: auto;
				min-width: 100px;
				padding: 2px 10px;
				text-align: center;
				font-size: 1.3rem;
				&._player {
					min-width: 120px;
					text-align: left;
				}
				& + .mod-newsLabel { margin-left: 5px; }
			}
			.__title {
				margin: 20px 0 10px;
				font-size: 2.4rem;
			}
			.__subTitle {
				font-size: 2.0rem;
			}
			.__date {
				text-align: right;
				color: $color-txt2;
			}
			.__lead {
				margin-top: 20px;
			}
			.__tags {
				display: inline-block;
				margin-left: 10px;
				a {
					display: inline-block;
					color: $color-red;
					transition: opacity .3s;
					&:hover { opacity: .55; }
				}
			}
		}
		&-body {
			padding-bottom: 60px;
			border-bottom: 1px solid $color-border2;
		}
		@include media(sp) {
			&-head {
				.__tags {
					margin-top: 5px;
					margin-left: 5px;
				}
			}
		}
	}
/*
---
name: mod-newsNav ニュース詳細下部ナビ
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-newsNav {
		background-color: $color-light-red;
		padding: 20px 0;
		.__inner {
			display: flex;
			justify-content: space-between;
		}
		a {
			color: $color-red;
			svg {
				width: 20px; height: 20px;
				fill: $color-red;
				vertical-align: middle;
			}
		}
		.__prev { svg { margin-right: 10px; } }
		.__next { svg { margin-left: 10px; } }
	}

/*
---
name: mod-newsShare ニュース詳細シェアボタン
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-newsShare {
		width: 500px;
		margin-left: auto;
		margin-right: auto;
		padding: 40px;
		text-align: center;
		p	{
			display: inline-block;
			margin-right: 50px;
			vertical-align: middle;
		}
		ul {
			display: inline-block;
			li {
				display: inline-block;
				margin-right: 20px;
				&:last-child { margin-right: 0; }
				img {
					width: 30px;
					vertical-align: middle;
				}
			}
		}
		@include media(sp) {
			width: 100%;
			padding: 30px 10px;
			p	{ margin-right: 20px; }
		}
	}

/*
---
name: mod-mediaList メディア一覧（ボックスリンク）
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-mediaList {
		a { display: block; }
		.__img {
			border: 1px solid $color-border2;
		}
		.__label {
			margin-top: 10px;
			letter-spacing: -.4em;
			> span {
				display: inline-block;
				letter-spacing: normal;
				margin-right: 5px;
				&:last-child { margin-right: 0; }
			}
			.mod-newsLabel {
				display: inline-block;
				padding: 0 5px;
				font-size: 1.3rem;
				&._player { width: 120px; }
			}
		}
		.__title {
			margin: 10px 0;
			line-height: 1.5;
		}
		.__lead {
			line-height: 1.5;
		}
		.__tag {
			letter-spacing: -.4em;
			> span {
				display: inline-block;
				letter-spacing: normal;
				margin-right: 10px;
				&:last-child { margin-right: 0; }
			}
			a {
				display: inline-block;
				color: $color-red;
				font-size: 1.4rem;
				&._player { color: $color-txt1; }
			}
		}
		time {
			display: block;
			color: $color-txt2;
			font-size: 1.4rem;
		}
		@include media(tablet) {
			.__label {
				span {
					font-size: 1.1rem;
					text-align: left;
				}
			}
		}
	}

/*
---
name: mod-pulldown 汎用プルダウン（JS併用）
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-pulldown {
		position: absolute;
		right: 0;
		width: 260px;
		height: 50px;
		background-color: #fff;
		z-index: 50;
		cursor: pointer;

		&-wrap {
			position: relative;
			padding-bottom: 50px;
			margin-bottom: 40px;
		}
		&-head {
			width: 100%;
			height: 100%;
			position: relative;
			border: 1px solid $color-border2;
			font-size: 1.6rem;

			span {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				right: 0; left: 0;
				margin: auto;
				padding: 0 20px;
				font-size: 1.5rem;
			}
			svg {
				position: absolute;
				top: 50%;
				right: 20px;
				transform: translateY(-50%);
				fill: $color-red;
				transition: .4s;
			}
			.is-active & {
				.icon {
					transform: translateY(-50%) rotateX(180deg);
				}
			}
		}
		&-body {
			display: none;
			&.is-active { display: block; }

			li {
				a {
					display: block;
					padding: 9px 20px;
					background-color: #fff;
					border-left: 1px solid $color-border2;
					border-right: 1px solid $color-border2;
					border-bottom: 1px dotted $color-border2;
					font-size: 1.5rem;
					transition: .4s;
					&:hover {
						background-color: $color-red;
						color: #fff;
					}
				}
				&:last-child a {
					border-bottom-style: solid;
				}
			}
		}
		@include media(tablet) {
			&-body {
				li a {
					&:hover {
						background-color: #fff;
						color: $color-txt1;
					}
				}
			}
		}
		@include media(sp) {
			right: 0; left: 0;
			margin: auto;

			&-wrap {
				padding-bottom: 70px;
			}
			&-head {
				font-size: 1.4rem;
				padding: 10px 20px;
			}
			&-body {
				li {
					a {
						padding: 10px 20px;
						font-size: 1.4rem;
					}
				}
			}
		}
	}

/*
---
name: mod-paginate ページャー
tag:
- input
category:
- module
- module/block
---
```html
<div class="mod-paginate mb20">
	<a href="#" class="mod-paginate-prev disabled"></a>
	<span class="mod-paginate-index">1</span>
	<a href="#" class="mod-paginate-index">2</a>
	<a href="#" class="mod-paginate-index">3</a>
	<a href="#" class="mod-paginate-next"></a>
</div>
```
---
*/
	&-paginate {
		text-align: center;

		&-index, &-prev, &-next {
			position: relative;
			display: inline-block;
			width: 50px;
			height: 50px;
			margin-right: 5px;
			margin-left: 5px;
			background-color: #fff;
			border: 1px solid $color-red;
			color: $color-red;
			font-size: 1.6rem;
			vertical-align: middle;
			line-height: 50px;
			transition: .4s;
			svg {
				position: absolute;
				top: 50%; left: 0; right: 0;
				transform: translateY(-50%);
				margin: 0 auto;
				width: 20px;
				height: 20px;
				fill: $color-red;
				transition: .4s;
			}
			&:hover {
				background-color: $color-red;
				color: #fff;
				svg { fill: #fff; }
			}
		}
		> span {
			background-color: $color-red;
			color: #fff;
			svg { fill: #fff; }
		}
		&-prev, &-next {
			border: none;
		}
		@include media(tablet) {
			position: relative;
			width: 100%;
			&-index, &-prev, &-next {
				margin-bottom: 5px;
				&:hover {
					background-color: #fff;
					color: $color-txt1;
					svg { fill: $color-txt1; }
				}
			}
			&-prev {
				position: absolute;
				left: 0;
			}
			&-next {
				position: absolute;
				right: 0;
			}
		}
		@include media(sp) {
			&-index, &-prev, &-next {
				width: 36px;
				height: 40px;
				margin-right: 3px;
				margin-left: 3px;
				font-size: 1.4rem;
				line-height: 40px;
			}
		}
	}

/*
---
name: mod-tab 汎用タブ（pure併用）
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-tab {
		a, span {
			display: block;
			margin-top: 20px;
			padding: 10px;
			line-height: 1.5;
			border-bottom: 2px solid #fff;
			text-align: center;
			cursor: pointer;
			transition: .3s;
			&:hover {
				color: #fff;
				background-color: $color-red;
				border-bottom-color: $color-red;
			}
			&.is-active {
				color: $color-red;
				border-bottom: 2px solid $color-red;
				pointer-events: none;
			}
		}
		@include media(tablet) {
			a:hover {
				background-color: #fff;
				border-bottom-color: #fff;
				color: $color-txt1;
			}
		}

		// アコーディオン付き
		.js-yearTab & {
			&:nth-of-type(2) { display: none; }
			.js-yearTab-switch {
				svg {
					display: inline-block;
					margin-left: 10px;
					width: 20px; height: 20px;
					fill: $color-red;
					vertical-align: middle;
					transition: .3s;
				}
				&:hover {
					svg { fill: #fff; }
				}
			}
		}
		.js-yearTab.is-open & {
			.js-yearTab-switch svg { transform: rotateX(180deg); }
		}
	}

/*
---
name: mod-count カウンター
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-count {
		color: $color-red;
		font-size: 6.0rem;
		font-weight: 700;
		font-family: $font_alphabet;
		line-height: 1.0;
		@include media(sp) {
			font-size: 5.0rem;
		}
	}

/*
---
name: mod-irCalendar IRカレンダー
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-irCalendar {
		display: flex;
		width: 100%;
		text-align: center;
		._term {
			width: 25%;
			letter-spacing: -.4em;
			border-right: 1px solid $color-border2;
			&:last-child { border-right: none; }
			> span {
				display: block;
				padding: 10px;
				background-color: $color-red;
				color: #fff;
				letter-spacing: normal;
			}
			ul {
				display: flex;
				li {
					width: 33.3333%;
					border-right: 1px solid $color-border2;
					letter-spacing: normal;
					&:last-child { border-right: none; }
					span {
						display: block;
						&:first-child {
							padding: 10px 3px;
							background-color: #f9eeee;
						}
						&:nth-child(2) {
							padding: 20px 3px;
							line-height: 1.5;
							font-size: 1.4rem;
						}
					}
				}
			}
		}
		@include media(tablet) {
			flex-wrap: wrap;
			._term {
				width: 50%;
			}
		}
	}

/*
---
name: mod-sideNav サイドナビ（追従右ナビ）
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-sideNav {
		&-wrap {
			position: relative;
			display: flex;
		}
		&-content {
			position: relative;
			width: calc(100% - 300px);
			z-index: 901;
		}
		&-nav {
			position: relative;
			width: 300px;
			padding-left: 40px;
			background-color: #fff;
			z-index: 900;
			.__head {
				margin-bottom: 15px;
				color: $color-red;
				font-size: 2.0rem;
			}
			.__body {
				font-size: 1.6rem;
				line-height: 1.5;
				> li {
					margin-bottom: 15px;
					&:last-child { margin-bottom: 0; }
				}
			}
			.__subBody {
				margin-top: 10px;
				margin-left: 20px;
				font-size: 1.5rem;
				li {
					margin-bottom: 10px;
					&:last-child { margin-bottom: 0; }
				}
			}
			a {
				transition: .3s;
				&:hover { color: $color-red; }
				&.is-active { font-weight: 700; }
			}
			&.is-fixed {
				position: fixed;
				top: 150px;
			}
			.js-toggleBlock {
				max-height: 220px;
				overflow-y: scroll;
			}
		}
		@include media(tablet) {
			&-wrap {
				flex-wrap: wrap;
			}
			&-content {
				width: 100%;
			}
			&-nav {
				width: 100%;
				margin-top: 60px;
				padding-top: 20px;
				padding-left: 0;
				border-top: 1px solid $color-border2;
				&.is-fixed {
					display: block;
					top: auto;
					left: auto;
				}
				.js-toggleBlock {
					max-height: auto;
				}
			}
		}
	}

/*
---
name: mod-banner フッター上バナースライド
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-banner {
		margin-top: 150px;
		@include media(sp) { margin-top: 100px; }

		&-slide {
			position: relative;
			.slick-list { padding: 0 calc((100% - 1200px)/2); }
			li {
				padding: 0 20px;
				a {
					display: block;
					border: 1px solid $color-border2;
					&:focus { outline: none; }
				}
			}
			.slick-prev, .slick-next {
				outline: none;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: 50px;
				height: 50px;
				background-color: #fff;
				border: 1px solid $color-red;
				z-index: 10;
				svg {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 0; right: 0;
					margin: auto;
					width: 16px; height: 16px;
					fill: $color-red;
				}
			}
			.slick-prev { left: calc(((100% - 1200px)/2) - 50px); }
			.slick-next { right: calc(((100% - 1200px)/2) - 50px); }
			.slick-dots { display: none; }

			@media screen and (max-width: 1300px) {
				.slick-prev { left: 0; }
				.slick-next { right: 0; }
			}
			@include media(tablet) {
				li { padding: 0 10px; }
				.slick-prev { left: 5%; }
				.slick-next { right: 5%; }
				.slick-list { padding: 0 50px; }
			}
			@include media(sp) {
				li { padding: 0 50px; }
			}
		}
	}
/*
---
name: mod-tbl テーブル
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-tbl {
		&Wrap {
			overflow: auto;
			-webkit-overflow-scrolling: touch;
			&::-webkit-scrollbar {
				width: 0px;
			}
			&::-webkit-scrollbar:horizontal {
				height: 0px;
			}
			&.shadow { box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.15); }
			@include media(tablet) {
				.mod-tbl01 { white-space: nowrap; }
			}
		}
		&01 { // business配下用 緑ベース
			border-collapse: separate;
			border-spacing: 0;
			tr {
				th, td {
					border-right: 1px solid $color-gray;
					vertical-align: middle;
					line-height: 1.5;
					&:last-child { border-right: none; }
				}
				th {
					padding: 10px;
					background-color: $color-light-green2;
					text-align: center;
					font-weight: normal;
				}
				td {
					padding: 20px 15px;
					background-color: #fff;
					border-bottom: 1px solid $color-gray;
					.livecameraIcon {
						width: 30px;
						height: 30px;
						fill: $color-green;
						&.isNone { fill: $color-gray2; }
					}
					.mod-link02:hover { color: $color-light-green; }
				}
				&:last-child {
					td {
						border-bottom: none;
					}
				}
			}
		}
		&02 { // business配下用 緑ベース（※SPで段落ち）
			border-collapse: separate;
			border-spacing: 0;
			tr {
				th, td {
					padding: 15px 18px;
					border-bottom: 1px solid $color-gray;
					vertical-align: top;
				}
				th {
					background-color: $color-light-green2;
					font-weight: normal;
				}
				td {
					background-color: #fff;
					border-bottom: 1px solid $color-gray;
				}
				&:last-child {
					th, td {
						border-bottom: none;
					}
				}
			}
			@include media(tablet) {
				tr {
					th, td {
						display: block;
						width: 100%;
						border-bottom: none;
					}
				}
			}
		}
		&03 { // 赤ベース（※SPで段落ち）
			border-collapse: separate;
			border-spacing: 0;
			box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.15);
			width: 100%;
			tr {
				th, td {
					padding: 15px 18px;
					border-bottom: 1px solid $color-gray;
					vertical-align: middle;
					text-align: left;
				}
				th {
					background-color: #f9eeee;
					font-weight: normal;
					&._270 { width: 270px; }
					&._300 { width: 300px; }
				}
				td {
					background-color: #fff;
					border-bottom: 1px solid $color-gray;
					&.__name { width: 200px; }
					&.__btn {
						width: 150px;
						text-align: center;
					}
				}
				&:last-child {
					th, td {
						border-bottom: none;
					}
				}
			}
			@include media(tablet) {
				tr {
					th, td {
						display: block;
						width: 100%;
						border-bottom: none;
					}
					th {
						&._270, &._300 { width: 100%; }
					}
					td {
						&.__name,
						&.__btn { width: 100%; }
					}
				}
			}
		}
		&04 { // フォーム用
			border-collapse: separate;
			border-spacing: 0;
			width: 100%;
			&-required {
				position: absolute;
				top: 35px;
				left: 180px;
				display: inline-block;
				font-size: 1.4rem;
				line-height: 1;
				background: #b30000;
				color: #fff;
				padding: 4px 5px 6px;
			}
			p.input_error {
				color: #b30000;
			}
			p.subtext {
				display: inline-block;
				padding-left: 10px;
				font-size: 1.4rem;
			}
			tr {
				th, td {
					padding: 30px 30px 20px;
					border-bottom: 1px solid $color-gray;
					vertical-align: top;
					text-align: left;
				}
				th {
					position: relative;
					width: 30%;
					font-weight: normal;
				}
				td {
					width: 70%;
					&.nomsg { padding-bottom: 30px; }
				}
			}
			pre {
				font-family: $font_gothic;
			}
			&.request-params {
				th, td { padding: 20px 30px; }
			}
			@include media(tablet) {
				&-required {
					top: 20px;
					left: 140px;
				}
				p.subtext {
					display: block;
					padding-left: 0;
					padding-top: 10px;
				}
				tr {
					th, td {
						display: block;
						width: 100%;
						padding: 20px 0;
					}
					th {
						padding-bottom: 0;
						border-bottom: 0;
					}
					td {
						padding-top: 10px;
					}
				}
			}
		}
		&05 { // 03と同デザイン（※SPで段落ちしない）
			border-collapse: separate;
			border-spacing: 0;
			box-shadow: 0px 0px 30px 5px rgba(0,0,0,0.15);
			width: 100%;
			tr {
				th, td {
					padding: 15px 18px;
					border-bottom: 1px solid $color-gray;
					vertical-align: middle;
					text-align: left;
					&.ta_center { text-align: center; }
				}
				th {
					background-color: #f9eeee;
					font-weight: normal;
				}
				td {
					background-color: #fff;
					border-bottom: 1px solid $color-gray;
				}
				&:last-child {
					th, td {
						border-bottom: none;
					}
				}
			}
			@include media(tablet) {
				tr {
					th, td { padding: 10px; }
				}
			}
		}
	}

/*
---
name: mod-shadow 影付きブロック
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-shadow {
		background-color: #fff;
		box-shadow: 0px 0 10px 5px rgba(0, 0, 0, 0.15);
		@include media(sp) {
			box-shadow: 0px 0 5px 5px rgba(0, 0, 0, 0.15);
		}
	}

/*
---
name: mod-toggle 汎用アコーディオンブロック
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-toggle {
		border: 1px solid $color-gray;
		&-head {
			padding: 15px 50px 15px 30px;
			font-weight: 700;
		}
	}

/*
---
name: mod-qa Q&Aブロック
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-qa {
		border: 1px solid $color-gray;
		+ .mod-qa { margin-top: 30px; }
		&-q, &-a {
			position: relative;
			&:before {
				position: absolute;
				display: block;
				left: 20px;
				width: 30px;
				height: 30px;
				text-align: center;
				color: #fff;
				font-size: 1.6rem;
				font-family: $font-alphabet;
				font-weight: bold;
				line-height: 30px;
			}
		}
		&-q {
			padding: 20px 20px 20px 70px;
			transition: opacity .4s;
			&:before {
				top: 20px;
				background-color: $color-txt1;
				content: 'Q'
			}
			&:hover {
				opacity: .55;
				@include media(tablet) { opacity: 1; }
			}
		}
		&-a {
			margin-top: 5px;
			padding: 0 20px 20px 70px;
			&:before {
				top: 0;
				background-color: $color-green2;
				content: 'A'
			}
		}
		@include media(sp) {
			+ .mod-qa { margin-top: vw(30px,$sp); }
			&-q, &-a {
				padding-left: vw(70px,$sp);
				&:before {
					width: vw(45px,$sp);
					height: vw(45px,$sp);
					left: vw(20px,$sp);
					line-height: vw(45px,$sp);
				}
			}
			&-q {
				padding: vw(30px,$sp) vw(60px,$sp) vw(30px,$sp) vw(80px,$sp);
				.js-toggle-switch { right: 20px; }
			}
			&-a {
				margin-top: vw(20px,$sp);
				padding: 0 vw(40px,$sp) vw(30px,$sp) vw(80px,$sp);
				font-size: 1.2rem;
			}
		}
	}

/*
---
name: mod-historyList 沿革一覧
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-historyList {
		li {
			display: flex;
			flex-flow: row wrap;
			align-content: flex-start;
		}
		.__year { width: 70px; }
		.__month { width: 60px; }
		.__title { width: calc(100% - 130px); }
		[lang="en"] & {
			.__year { width: 60px; }
			.__month { width: 110px; }
			.__title { width: calc(100% - 170px); }
		}
		@include media(tablet) {
			li {
				&:first-child {
					.__year {
						> span {
							display: block;
							padding-top: 0;
						}
					}
				}
			}
			.__year {
				width: 100%;
				padding-bottom: 10px;
				> span {
					display: block;
				}
			}
			.__month {
				width: 45px;
			}
			.__title {
				width: calc(100% - 45px);
			}
		}
	}

/*
---
name: mod-youtube YoutubeIframe埋め込みブロック
tag:
- block
category:
- module
- module/block
---
```html
<div class="mod-auto-center width-u-60">
	<div class="mod-youtube">
		<!-- 埋め込みソース -->
		<iframe width="560" height="315" src="https://www.youtube.com/embed/$ID$"
		frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope;
		picture-in-picture" allowfullscreen></iframe>
		<!-- 埋め込みソース ここまで -->
	</div>
</div>
```
*/
	&-youtube {
		position: relative;
		width: 100%;
		padding-top: 56.25%;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			right: 0;
			width: 100%!important;
			height: 100%!important;
		}
	}
/*
---
name: mod-detailSlide 詳細ページ用　サムネイル付きスライダー
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-detailSlide {
		.slick-slide {
			text-align: center;
			img {
				max-height: 500px;
			}
		}
		.slick-dots {
			display: flex;
			margin-top: 30px;
			> li {
				position: relative;
				width: calc((100% - 80px) /5);
				margin-right: 20px;
				text-align: center;
				&:before {
					position: absolute;
					display: block;
					content: '';
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: 2px solid $color-green2;
					opacity: 0;
					z-index: 1;
					transition: opacity .4s;
					.weightlifting & { border: 2px solid $color-orange; }
				}
				&.slick-active {
					&:before { opacity: 1; }
				}
				&:last-child { margin-right: 0; }

				img {
					max-height: 70px;
				}
			}
		}
		@include media(sp) {
			.slick-dots {
				margin-top: vw(30px,$sp);
				> li {
					width: calc((100% - 40px) /5);
					margin-right: 10px;
					img {
						max-height: 40px;
					}
				}
			}
		}
	}
/*
---
name: mod-flow お問い合わせ用　flow
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-flow {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		> li {
			width: 33.3333%;
			text-align: center;
			background: #f9eeee;
			padding: 14px 0;
			height: 60px;
			> p {
				position: relative;
				display: block;
				height: 100%;
				width: 100%;
				&:before {
					content: '';
					position: absolute;
					top: -17px;
					right: -24px;
					display: block;
					border-left: 24.2px solid #fff;
					border-top: 33px solid transparent;
					border-bottom: 33px solid transparent;
				}
				&:after {
					content: '';
					position: absolute;
					top: -14px;
					right: -21px;
					display: block;
					border-left: 22px solid $color-light-red;
					border-top: 30px solid transparent;
					border-bottom: 30px solid transparent;
				}
				> span {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					line-height: 1.5;
					width: 80%;
				}
			}
			&:last-child {
				> p {
					&:before {
						display: none;
					}
				}
			}
			&.is-active {
				color: #fff;
				background: $color-red;
				> p {
					&:after {
						border-left-color: $color-red;
					}
				}
			}
		}
		@include media(sp) {
			> li {
				padding: 12px 0;
				height: 45px;
				font-size: 1.2rem;
				> p {
					padding-left: 10px;
					&:before {
						top: -14px;
						right: -16px;
						display: block;
						border-left: 15.4px solid #fff;
						border-top: 24.75px solid transparent;
						border-bottom: 24.75px solid transparent;
					}
					&:after {
						top: -12px;
						right: -14px;
						border-left: 14px solid $color-light-red;
						border-top: 22.5px solid transparent;
						border-bottom: 22.5px solid transparent;
					}
				}
			}
		}
	}
/*
---
name: 汎用テキストボックス
tag:
  - input
category:
  - module
  - module/input
---
```html
<input type="text" class="mod-input">
```
*/
	&-input {
		padding: 5px 10px;
		background-color: #fff;
		border: 1px solid $color-gray;
		outline: none;

		&.input_error {
			background-color: #f9eeee;
		}
	}
	&-textarea {
		padding: 5px 10px;
		background-color: #fff;
		border: 1px solid $color-gray;
		outline: none;
		min-height: 140px;

		&.input_error {
			background-color: #f9eeee;
		}
	}
/*
---
name: 汎用チェックボックス
tag:
- input
category:
- module
- module/input
---
```html

```
*/
	&-check {
		&-input {
			opacity: 0;
			position: absolute;
		}
		&-text {
			display: inline-block;
			position: relative;
			cursor: pointer;
			padding-left: 34px;

			&:before {
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				display: block;
				height: 20px;
				width: 20px;
				border: 1px solid $color-gray;
				background-color: #fff;
			}
			&:after {
				content: "";
				position: absolute;
				top: 15px;
				left: 6px;
				display: block;
				width: 8px;
				height: 12px;
				border: solid 3px $color-red;
				border-left: 0;
				border-top: 0;
				transform: translateY(-50%) rotate(45deg);
				display: none;
			}
			@include media(sp) {
				&:before {
					top: 3px;
				}
				&:after {
					top: 11px;
				}
			}
		}
		&-input:checked ~ &-text {
			&:before {
				border-color: $color-red;
			}
			&:after {
				display: block;
			}
		}
	}

/*
---
name: 汎用ラジオボタン
tag:
- input
category:
- module
- module/input
---
```html

```
*/
	&-radio {
		&-input {
			opacity: 0;
			position: absolute;
		}
		&-text {
			display: inline-block;
			position: relative;
			cursor: pointer;
			padding-left: 34px;

			&:before {
				content: "";
				position: absolute;
				top: 7px;
				left: 0;
				display: block;
				height: 20px;
				width: 20px;
				border-radius: 50%;
				border: 1px solid $color-gray;
				background-color: #fff;
			}
			&:after {
				content: "";
				position: absolute;
				top: 11px;
				left: 4px;
				display: block;
				width: 12px;
				height: 12px;
				background-color: $color-red;
				border-radius: 50%;
				display: none;
			}
			@include media(sp) {
				font-size: 1.4rem;
				&:before {
					top: 3px;
				}
				&:after {
					top: 7px;
				}
			}
		}
		&-input:checked ~ &-text {
			&:before {
				border-color: $color-red;
			}
			&:after {
				display: block;
			}
		}
	}
/*
---
name: mod-bottomArrow 下三角付きボックス（ブロックレイアウト用）
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-bottomArrow {
		position: relative;
		margin-bottom: 60px;
		&:after {
			content: '';
			display: block;
			width: 30px;
			height: 22px;
			position: absolute;
			bottom: -45px;
			right: 0; left: 0;
			margin: 0 auto;
			border-top: 22px solid $color-txt1;
			border-left: 30px solid transparent;
			border-right: 30px solid transparent;
		}
	}
/*
---
name: mod-modal モーダル
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-modal {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		width: 1000px;
		max-width: 90%;
		height: 0;
		max-height: 90%;
		padding: 40px;
		background-color: #fff;
		box-shadow: 0 0 3px 3px rgba(0,0,0,0.3);
		@include media(tablet) {
			width: 94%;
			padding: 20px 10px;
		}

		&Img {
			cursor: pointer;
			transition: .3s;
			&:hover { opacity: .55; }
			@include media(tablet) {
				cursor: default;
				&:hover { opacity: 1; }
			}
		}

		&-overlay {
			display: none;
			opacity: 0;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.4);
			z-index: 1002;
			transition: .4s;
			&.is-active { display: block; }
			&.is-visible { opacity: 1; }

			@include media(sp) { height: 110%; }
		}
		&-inner {
			display: none;
			position: relative;
			text-align: center;
			.mod-modal-overlay & { display: block; }
		}
		&-closeArea {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1003;
		}
		&-closeBtn {
			cursor: pointer;
			position: absolute;
			top: 0;
			right: -45px;
			width: 40px;
			height: 40px;
			fill: #fff;
			z-index: 1003;
			@include media(sp) { right: 0; top: -45px; }
		}
	}

/*
---
name: mod-contactBlock お問い合わせフォーム フル幅ブロック
tag:
- block
category:
- module
- module/block
---
```html
```
*/
	&-contactBlock {
		border-bottom: 1px solid $color-gray;
		padding: 30px;
		.__head { margin-bottom: 30px; }
		.mod-tbl04-required {
			position: relative;
			top: auto; left: auto;
		}
		@include media(tablet) {
			padding: 20px 0;
			.__head { margin-bottom: 20px; }
		}
	}

}
